import React from "react";
import { useSelector } from "react-redux";
import { extractFields } from "../../../utility";

export const headerThreeFields = [
  {
    type: "button",
    label: ["Link text 1", "Link 1"],
    value: {
      text: "home",
      link: "#",
    },
  },
  {
    type: "button",
    label: ["Link text 2", "Link 2"],
    value: {
      text: "blog",
      link: "#",
    },
  },
  {
    type: "button",
    label: ["Link text 3", "Link 3"],
    value: {
      text: "services",
      link: "#",
    },
  },
  {
    type: "button",
    label: ["Link text 4", "Link 4"],
    value: {
      text: "about",
      link: "#",
    },
  },

  {
    type: "button",
    label: ["Link text 5", "Link 5"],
    value: {
      text: "contact",
      link: "#",
    },
  },
  {
    type: "button",
    label: ["Link text 6", "Link 6"],
    value: {
      text: "contact",
      link: "#",
    },
  },

  {
    type: "string",
    label: "Title",
    value: "We Get Dentists More of What They Want",
  },

  {
    type: "image",
    label: "Image",
    value: "",
    file: null,
    displayValue: "img/banner-three.jpg",
  },
  {
    type: "image",
    label: "Logo",
    value: "",
    file: null,
    displayValue: "img/logo-three.png",
  },
  {
    type: "string",
    label: "phone",
    value: "",
  },
];

const HeaderThree = (props) => {
  const headerFields = useSelector((state) =>
    extractFields(state, "header", props.instance, 2)
  );
  return (
    // <!-- Header Three Start -->
    // <!-- On this line ADD className= header_sticky  for stiky menu -->
    <>
      <div className="header_area_three">
        <div className="container">
          <div className="header_area  header_three">
            <div className="row">
              <div className="col-sm-3">
                <div className="main_logo_three">
                  <a href="">
                    <img
                      style={{ maxWidth: "150px", maxHeight: "150px" }}
                      src={headerFields[8].displayValue}
                      className="logo_three"
                      alt="logo"
                    />
                  </a>
                </div>
              </div>
              <div className="col-sm-9">
                <div className="main_navigation_three clearfix">
                  <ul id="main_navigation_three" className="">
                    {[0, 1, 2, 3, , 4, 5].map((idx) => (
                      <li key={idx}>
                        <a href={headerFields[idx].value.link}>
                          {headerFields[idx].value.text}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="call_now_three">
                  <p>
                    <a href="tel:303.377.2295">CONTACT US 303.377.2295</a>{" "}
                    <a href="#">
                      <i className="fa fa-facebook"></i>
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="header_banner_area_three">
        <img src={headerFields[7].displayValue} alt="" />
      </div>
      {/* // <!-- Header Three End --> */}
    </>
  );
};

export default HeaderThree;
