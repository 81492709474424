import React from "react";
import { useSelector } from "react-redux";
import { extractFields } from "../../../utility";

export const BoxedSectionFiveFields = [
  {
    type: "string",
    label: "Title 1",
    value: "residential",
  },
  {
    type: "string",
    label: "Description 1",
    value:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque tellus eros, aliquam quis cursus in, tempor sed eros. Etiam hendrerit justo non velit pretium pulvinar vitae nec turpis. Nulla a mi dolor. Cras a nibh ligula. Suspendisse quis tellus mattis, lacinia eros a, porttitor metus. Curabitur placerat velit et purus volutpat cursus. Proin consequat dolor id facilisis blandit. In id viverra erat. Sed maximus dapibus metus, ac rutrum magna consequat nec. Donec maximus, metus condimentum.",
  },
  {
    type: "image",
    label: "Icon 1",
    value: "",
    file: null,
    displayValue: "img/cat-icon-1.png",
  },
  {
    type: "string",
    label: "Title 2",
    value: "residential",
  },
  {
    type: "string",
    label: "Description 2",
    value:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque tellus eros, aliquam quis cursus in, tempor sed eros. Etiam hendrerit justo non velit pretium pulvinar vitae nec turpis. Nulla a mi dolor. Cras a nibh ligula. Suspendisse quis tellus mattis, lacinia eros a, porttitor metus. Curabitur placerat velit et purus volutpat cursus. Proin consequat dolor id facilisis blandit. In id viverra erat. Sed maximus dapibus metus, ac rutrum magna consequat nec. Donec maximus, metus condimentum.",
  },
  {
    type: "image",
    label: "Icon 2",
    value: "",
    file: null,
    displayValue: "img/cat-icon-2.png",
  },
];

const BoxedSectionFive = (props) => {
  const boxedSectionsFields = useSelector((state) =>
    extractFields(state, "boxSection", props.instance, 4)
  );

  return (
    // <!-- Section Two Start -->

    <div className="boxed_section_category_area">
      <div className="container">
        <div className="boxed_section_category">
          <div className="row">
            <div className="col-sm-6 col-md-5 col-md-offset-1">
              <div className="boxed_section_single_category">
                <div className="boxed_section_category_img">
                  <img src={boxedSectionsFields[2].displayValue} alt="" />
                </div>
                <h3>{boxedSectionsFields[0].value}</h3>
                <p>{boxedSectionsFields[1].value}</p>
                <a href="">
                  <i className="fa fa-arrow-right"></i>
                </a>
              </div>
            </div>
            <div className="col-sm-6 col-md-5">
              <div className="boxed_section_single_category">
                <div className="boxed_section_category_img">
                  <img src={boxedSectionsFields[5].displayValue} alt="" />
                </div>
                <h3> {boxedSectionsFields[3].value}</h3>
                <p>{boxedSectionsFields[4].value}</p>
                <a href="">
                  <i className="fa fa-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BoxedSectionFive;
