import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { extractFields } from "../../../utility";

export const SectionFifteenFields = [
  {
    type: "string",
    label: "Client 1 Name",
    value: "Jhon Doe",
  },
  {
    type: "string",
    label: "Feedback 1",
    value:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi sit amet augue non felis consectetur tristique non eget ligula. Phasellus ullamcorper tincidunt molestie. Pellentesque eu velit scelerisque, interdum nisl a, auctor lectus.",
  },
  {
    type: "image",
    label: "Client 1 Image",
    value: "",
    file: null,
    displayValue: "img/men.png",
  },
  {
    type: "string",
    label: "Client 2 Name",
    value: "Client 2",
  },
  {
    type: "string",
    label: "Feedback 2",
    value:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi sit amet augue non felis consectetur tristique non eget ligula. Phasellus ullamcorper tincidunt molestie. Pellentesque eu velit scelerisque, interdum nisl a, auctor lectus.",
  },
  {
    type: "image",
    label: "Client 2 Image",
    value: "",
    file: null,
    displayValue: "img/men.png",
  },
  {
    type: "image",
    label: "Background Image",
    value: "",
    file: null,
    displayValue: "img/testi-bg14.jpg",
  },
];

const SectionFifteen = (props) => {
  const sectionFields = useSelector((state) =>
    extractFields(state, "section", props.instance, 14)
  );

  const bgImage =
    "url(" +
    sectionFields[6].displayValue.replace(/^"(.+(?="$))"$/, "$1") +
    ")";

  useEffect(() => {
    window.sectionFifteen();
  }, []);

  return (
    // <!-- Section Eight Start -->
    <div
      style={{ backgroundImage: bgImage }}
      className="section_fifteen_slider_area"
    >
      <div className="container">
        <div className="section_fifteen_slider">
          <div className="row">
            <div className="col-sm-12">
              <div className="section_fifteen_home_slider">
                <div className="section_fifteen_single_slide">
                  <img src={sectionFields[2].displayValue} alt="" />
                  <p>{sectionFields[1].value}</p>{" "}
                  <h3>{sectionFields[0].value}</h3>
                </div>
                <div className="section_fifteen_single_slide">
                  <img src={sectionFields[5].displayValue} alt="" />
                  <p>{sectionFields[4].value}</p>{" "}
                  <h3>{sectionFields[3].value}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    // <!-- Section Eight End -->
  );
};

export default SectionFifteen;
