import React from "react";
import { useSelector } from "react-redux";
import { extractFields } from "../../../utility";

export const SectionSixFields = [
  {
    type: "string",
    label: "Title",
    value: "How we can help you today?",
  },
  {
    type: "string",
    label: "Sub Title",
    value:
      "For immediate assistance, please contact us at 512-645-0679. For all other requests, please leave your information and a brief description of the services you are requesting.",
  },
  {
    type: "image",
    label: "Background Image",
    value: "",
    file: null,
    displayValue: "img/contact.png",
  },
];

const SectionSix = (props) => {
  const sectionFields = useSelector((state) =>
    extractFields(state, "section", props.instance, 5)
  );

  const bgImage =
    "url(" +
    sectionFields[2].displayValue.replace(/^"(.+(?="$))"$/, "$1") +
    ")";

  return (
    // <!-- Section Eight Start -->
    <div style={{ backgroundImage: bgImage }} className="section_one_contact_area">
      <div className="container">
        <div className="section_one_contact">
          <div className="row">
            <div className="col-sm-12">
              <div className="section_one_sec_title">
                <h2>{sectionFields[0].value}</h2>
                <div className="section_one_separetor"></div>
                <p>{sectionFields[1].value} </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    // <!-- Section Eight End -->
  );
};

export default SectionSix;
