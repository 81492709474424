import React from "react";
import { useSelector } from "react-redux";
import { extractFields } from "../../../utility";

export const BoxedSectionSixFields = [
  {
    type: "string",
    label: "Title",
    value: "At John Koelle Piano we specialize in the custom restoration",
  },
  {
    type: "string",
    label: "Description",
    value:
      " At John Koelle Piano we specialize in the custom restoration of Steinways and other fine pianos. We offer the Highest Quality Craftsmanship at honest prices.",
  },
  {
    type: "image",
    label: "Image 1",
    value: "",
    file: null,
    displayValue: "img/single-service-1.jpg",
  },
  {
    type: "string",
    label: "Title 1",
    value: "honest prices",
  },
  {
    type: "image",
    label: "Image 2",
    value: "",
    file: null,
    displayValue: "img/single-service-1.jpg",
  },
  {
    type: "string",
    label: "Title 2",
    value: "honest prices",
  },
  {
    type: "image",
    label: "Image 3",
    value: "",
    file: null,
    displayValue: "img/single-service-1.jpg",
  },
  {
    type: "string",
    label: "Title 3",
    value: "honest prices",
  },
  {
    type: "image",
    label: "Image 4",
    value: "",
    file: null,
    displayValue: "img/single-service-1.jpg",
  },
  {
    type: "string",
    label: "Title 4",
    value: "honest prices",
  },
];

const BoxedSectionSix = (props) => {
  const boxedSectionsFields = useSelector((state) =>
    extractFields(state, "boxSection", props.instance, 5)
  );

  return (
    // <!-- Section Two Start -->
    <div className="boxed_seventeen_service_area">
      <div className="container">
        <div className="boxed_seventeen_service">
          <div className="row">
            <div className="col-sm-12">
              <div className="boxed_seventeen_service_title">
                <h3>{boxedSectionsFields[0].value}</h3>
                <p>{boxedSectionsFields[1].value}</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 col-md-3">
              <div className="boxed_seventeen_single_service">
                <div className="boxed_seventeen_img">
                  <img src={boxedSectionsFields[2].displayValue} alt="" />
                </div>
                <p> {boxedSectionsFields[3].value}</p>
              </div>
            </div>
            <div className="col-sm-6 col-md-3">
              <div className="boxed_seventeen_single_service">
                <div className="boxed_seventeen_img">
                  <img src={boxedSectionsFields[4].displayValue} alt="" />
                </div>
                <p> {boxedSectionsFields[5].value}</p>
              </div>
            </div>
            <div className="col-sm-6 col-md-3">
              <div className="boxed_seventeen_single_service">
                <div className="boxed_seventeen_img">
                  <img src={boxedSectionsFields[6].displayValue} alt="" />
                </div>
                <p> {boxedSectionsFields[7].value}</p>
              </div>
            </div>
            <div className="col-sm-6 col-md-3">
              <div className="boxed_seventeen_single_service">
                <div className="boxed_seventeen_img">
                  <img src={boxedSectionsFields[8].displayValue} alt="" />
                </div>
                <p> {boxedSectionsFields[9].value}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BoxedSectionSix;
