import React from "react";
import { useSelector } from "react-redux";
import { extractFields } from "../../../utility";

export const BoxedSectionNineFields = [
  {
    type: "image",
    label: "Image 1",
    value: "",
    file: null,
    displayValue: "img/Screenshot_3.jpg",
  },
  {
    type: "string",
    label: "Title 1",
    value: "Tesla to join Nasdaq 100 as Oracle defects to NYSE",
  },
  {
    type: "string",
    label: "Description 1",
    textare: true,
    value:
      "Tesla to join Nasdaq 100 as Oracle defects to NYSE.Tesla to join Nasdaq 100 as Oracle defects to NYSE. Tesla to join Nasdaq 100 as Oracle defects to NYSE.Tesla to join Nasdaq 100 as Oracle defects to NYSE. Tesla to join Nasdaq 100 as Oracle defects to NYSE.Tesla to join Nasdaq 100 as Oracle defects to NYSE. Tesla to join Nasdaq 100 as Oracle defects to NYSE.Tesla to join Nasdaq 100 as Oracle defects to NYSE.",
  },
  {
    type: "image",
    label: "Image 2",
    value: "",
    file: null,
    displayValue: "img/Screenshot_3.jpg",
  },
  {
    type: "string",
    label: "Title 2",
    value: "Tesla to join Nasdaq 100 as Oracle defects to NYSE",
  },
  {
    type: "string",
    label: "Description 2",
    textare: true,
    value:
      "Tesla to join Nasdaq 100 as Oracle defects to NYSE.Tesla to join Nasdaq 100 as Oracle defects to NYSE. Tesla to join Nasdaq 100 as Oracle defects to NYSE.Tesla to join Nasdaq 100 as Oracle defects to NYSE. Tesla to join Nasdaq 100 as Oracle defects to NYSE.Tesla to join Nasdaq 100 as Oracle defects to NYSE. Tesla to join Nasdaq 100 as Oracle defects to NYSE.Tesla to join Nasdaq 100 as Oracle defects to NYSE.",
  },
  {
    type: "image",
    label: "Image 3",
    value: "",
    file: null,
    displayValue: "img/Screenshot_3.jpg",
  },
  {
    type: "string",
    label: "Title 3",
    value: "Tesla to join Nasdaq 100 as Oracle defects to NYSE",
  },
  {
    type: "string",
    label: "Description 3",
    textare: true,
    value:
      "Tesla to join Nasdaq 100 as Oracle defects to NYSE.Tesla to join Nasdaq 100 as Oracle defects to NYSE. Tesla to join Nasdaq 100 as Oracle defects to NYSE.Tesla to join Nasdaq 100 as Oracle defects to NYSE. Tesla to join Nasdaq 100 as Oracle defects to NYSE.Tesla to join Nasdaq 100 as Oracle defects to NYSE. Tesla to join Nasdaq 100 as Oracle defects to NYSE.Tesla to join Nasdaq 100 as Oracle defects to NYSE.",
  },
];

const BoxedSectionNine = (props) => {
  const boxedSectionsFields = useSelector((state) =>
    extractFields(state, "boxSection", props.instance, 8)
  );

  return (
    // <!-- Section Two Start -->

    <div className="boxed_section_eight_area">
      <div className="container">
        <div className="boxed_section_eight">
          <div className="row">
            {[0, 1, 2].map((idx) => (
              <div key={idx} className="col-sm-4">
                <div className="boxed_section_seven_single_blog">
                  <img src={boxedSectionsFields[idx * 3].displayValue} alt="" />
                  <h3>{boxedSectionsFields[idx * 3 + 1].value}</h3>
                  <p>{boxedSectionsFields[idx * 3 + 2].value}</p>
                  {/* <a href="#" className="boxed_section_seven_read_more">
                  Read More
                </a> */}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BoxedSectionNine;
