import React from "react";
import { useSelector } from "react-redux";
import { extractFields } from "../../../utility";

export const TitleThreeFields = [
  { type: "string", label: "Title", value: "GET THE LEGAL HELP YOU NEED" },
  {
    type: "string",
    label: "Sub Title",
    value: "Complete this form to contact an experienced lawyer",
  },
];

const TitleThree = (props) => {
  const titleFields = useSelector((state) =>
    extractFields(state, "title", props.instance, 2)
  );

  return (
    // <!-- Title Two Start -->
    <div className="section_title_two_area">
      <div className="container">
        <div className="section_title_two_inner">
          <div className="row">
            <div className="col-sm-12">
              <div className="title_div">
                <h1>{titleFields[0].value}</h1>
                <h2 className="sec_title_two">{titleFields[1].value}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    // <!-- Title Two End -->
  );
};

export default TitleThree;
