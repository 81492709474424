import React from "react";
import { useSelector } from "react-redux";
import { extractFields } from "../../../utility";

export const SectionNineFields = [
  {
    type: "string",
    label: "Title",
    value:
      "tesque faucibus accumsan risus, in convallis orci feugiat non. Integer auctor augue mi, et pharetra libero commodo et. Cras auctor faucibus eros iaculis tincidunt. Quisque luctus lacus ante, ac hendrerit nisl rhoncus eu. Integer a est id lectus scelerisque laoreet non eget arcu. Praesent eget nisi vel turpis sollicitudin venenatis. Donec tristique nibh non nibh imperdiet mattis. Vivamus nec sodales ante, ut aliquet turpis. vel turpis sollicitudin venenatis. Donec tristique nibh non nibh imperdiet mattis. Vivamus nec sodales ante, ut aliquet turpis. Vestibulum eget Quisque luctus lacus ante, ac hendrerit nisl rhoncus eu. Integer a est id lectus scelerisque laoreet non eget arcu. Praesent eget nisi vel turpis sollicitudin venenatis. Donec tristique nibh non nibh imperdiet mattis. Vivamus nec sodales ante, ut aliquet turpis. Vestibulum eget",
  },
  {
    type: "image",
    label: "Image",
    value: "",
    file: null,
    displayValue: "img/text-logo.png",
  },
];

const SectionNine = (props) => {
  const sectionFields = useSelector((state) =>
    extractFields(state, "section", props.instance, 8)
  );

  return (
    // <!-- Section Eight Start -->
    <div className="our_story_area">
      <div className="container">
        <div className="our_story">
          <div className="row">
            <div className="col-sm-12">
              <div className="story_text_block">
                <img src={sectionFields[1].displayValue} alt="" />
                <p>{sectionFields[0].value}</p>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    // <!-- Section Eight End -->
  );
};

export default SectionNine;
