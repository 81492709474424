export const CHANGE_LAYOUT = "CHANGE_LAYOUT";
export const CHANGE_ORDER = "CHANGE_ORDER";
export const ADD_SECTION = "ADD_SECTION";
export const DELETE_SECTION = "DELETE_SECTION";

export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const CHECKING_AUTH_STATE = "CHECKING_AUTH_STATE";

export const CHANGE_FIELDS = "CHANGE_FIELDS";
export const SET_SECTIONS = "SET_SECTIONS";
export const SET_FIELDS = "SET_FIELDS";
export const RESET_LAYOUT = "RESET_LAYOUT";

export const SET_PREVIEW_URL = "SET_PREVIEW_URL";
export const SET_MAIN_COLOR = "SET_MAIN_COLOR";
export const SET_SECTION_COLOR = "SET_SECTION_COLOR";
