import React from "react";
import { useSelector } from "react-redux";
import { extractFields } from "../../../utility";

export const BoxedSectionSevenFields = [
  {
    type: "string",
    label: "Title",
    value: "Our Services",
  },
  {
    type: "string",
    label: "Description",
    value:
      "Whatever the job, large or small, TruTec Electric is standing by to meet your needs and exceed your expectations! Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut  enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip",
  },
  {
    type: "string",
    label: "Service 1 Title",
    value: "Panel Upgrade",
  },

  {
    type: "image",
    label: "Service 1 Image",
    value: "",
    file: null,
    displayValue: "img/single-service-1.jpg",
  },
  {
    type: "string",
    label: "Service 2 Title",
    value: "Panel Upgrade",
  },

  {
    type: "image",
    label: "Service 2 Image",
    value: "",
    file: null,
    displayValue: "img/single-service-2.jpg",
  },
  {
    type: "string",
    label: "Service 3 Title",
    value: "Panel Upgrade",
  },

  {
    type: "image",
    label: "Service 3 Image",
    value: "",
    file: null,
    displayValue: "img/single-service-3.jpg",
  },
  {
    type: "string",
    label: "Service 4 Title",
    value: "Panel Upgrade",
  },

  {
    type: "image",
    label: "Service 4 Image",
    value: "",
    file: null,
    displayValue: "img/single-service-4.jpg",
  },
];

const BoxedSectionSeven = (props) => {
  const boxedSectionsFields = useSelector((state) =>
    extractFields(state, "boxSection", props.instance, 6)
  );

  return (
    // <!-- Section Two Start -->

    <div className="section_one_service_area">
      <div className="container">
        <div className="section_one_service">
          <div className="row">
            <div className="col-sm-12">
              <div className="section_one_sec_title">
                <h2>{boxedSectionsFields[0].value}</h2>
                <p>{boxedSectionsFields[1].value}</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-3">
              <div className="section_one_single_service">
                <img src={boxedSectionsFields[3].displayValue} alt="" />
                <a href=""> {boxedSectionsFields[2].value} </a>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="section_one_single_service">
                <img src={boxedSectionsFields[5].displayValue} alt="" />
                <a href=""> {boxedSectionsFields[4].value} </a>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="section_one_single_service">
                <img src={boxedSectionsFields[7].displayValue} alt="" />
                <a href=""> {boxedSectionsFields[6].value} </a>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="section_one_single_service">
                <img src={boxedSectionsFields[9].displayValue} alt="" />
                <a href=""> {boxedSectionsFields[8].value} </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BoxedSectionSeven;
