import React from "react";
import { useSelector } from "react-redux";
import { extractFields } from "../../../utility";

export const SectionElevenFields = [
  {
    type: "string",
    label: "Title",
    value: "Piano we specialize",
  },
  {
    type: "string",
    label: "Description",
    value:
      "At John Koelle Piano we specialize in the custom restoration of Steinways and other fine pianos. We offer the Highest Quality Craftsmanship at honest prices. We rebuild for many institutions and professionals as well as a large private clientele. We also offer in home service including tuning and At John Koelle Piano we specialize in the custom restoration of Steinways and other fine pianos. We offer the Highest Quality Craftsmanship at honest prices. We rebuild for many institutions and professionals as well as a large private clientele. We also offer in home service including tuning and At John Koelle Piano we specialize in the custom restoration",
  },
];

const SectionEleven = (props) => {
  const sectionFields = useSelector((state) =>
    extractFields(state, "section", props.instance, 10)
  );

  return (
    // <!-- Section Eight Start -->
    <div className="section_eleven_area">
      <div className="container">
        <div className="section_eleven">
          <div className="row">
            <div className="col-sm-12">
              <div className="section_eleven_single_content">
                <h3>{sectionFields[0].value}</h3>
                <p>{sectionFields[1].value}</p>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    // <!-- Section Eight End -->
  );
};

export default SectionEleven;
