import React from "react";
import { useSelector } from "react-redux";
import { extractFields } from "../../../utility";
import SectionSeven from "../SectionSeven";

export const SectionSeventeenFields = [
  {
    type: "string",
    label: "Title",
    value: "WELCOME",
  },
  {
    type: "string",
    label: "Paragraph 1",
    value:
      "“Kazmierski & Bowen Orthodontics provided gorgeous smiles for all of my children. We have loved our experience there and we look forward to returning for each appointment!”",
  },
  {
    type: "string",
    label: "Paragraph 2",
    value:
      "As a service to our clients, we also have wellness plans to help make vaccines and other preventative health needs more affordable. Experience the top animal hospital in Fayette County, Georgia. Make an appointment for the wellness of your pet: Let’s start a relationship that lasts a lifetime.",
  },
  {
    type: "string",
    label: "Image Caption",
    value: "Veterinarian, Atlanta",
  },

  {
    type: "image",
    label: "Image",
    value: "",
    file: null,
    displayValue: "img/VetPhoto.jpg",
  },
];

const SectionSevenTeen = (props) => {
  const sectionFields = useSelector((state) =>
    extractFields(state, "section", props.instance, 16)
  );

  const bgImage =
    "url(" +
    sectionFields[4].displayValue.replace(/^"(.+(?="$))"$/, "$1") +
    ")";

  return (
    // <!-- Section Eight Start -->
    <div className="section_seventeen_welcome_area">
      <div className="container">
        <div className="section_seventeen_welcome">
          <div className="row">
            <div className="col-sm-6">
              <div className="section_seventeen_single_welcome">
                <img src={sectionFields[4].displayValue} alt="" />
                <p>{sectionFields[3].value}</p>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="section_seventeen_single_welcome">
                <h1>{sectionFields[0].value}</h1>
                <p>{sectionFields[1].value}</p> <p>{sectionFields[2].value}</p>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    // <!-- Section Eight End -->
  );
};

export default SectionSevenTeen;
