import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { extractFields } from "../../../utility";

export const BoxedSectionEightFields = [
  {
    type: "string",
    label: "Title 1",
    value: "Award Winning Service",
  },
  {
    type: "string",
    label: "Sub Title",
    value: "What Our Customers Think of Our Work",
  },
  {
    type: "string",
    label: "Customer 1 Name",
    value: "Alex/Katelyn Loeffelman",
  },
  {
    type: "string",
    label: "Customer 1 Feedback",
    value:
      "“Nick and Trevor did an awesome job rewriting an outlet for our TV. They checked multiple options and took their time to make sure I had all the details and options. Will definitely use them again!”",
  },
  {
    type: "string",
    label: "Customer 2 Name",
    value: "Alex/Katelyn Loeffelman",
  },
  {
    type: "string",
    label: "Customer 2 Feedback",
    value:
      "“Nick and Trevor did an awesome job rewriting an outlet for our TV. They checked multiple options and took their time to make sure I had all the details and options. Will definitely use them again!”",
  },
  {
    type: "string",
    label: "Customer 3 Name",
    value: "Alex/Katelyn Loeffelman",
  },
  {
    type: "string",
    label: "Customer 3 Feedback",
    value:
      "“Nick and Trevor did an awesome job rewriting an outlet for our TV. They checked multiple options and took their time to make sure I had all the details and options. Will definitely use them again!”",
  },
];

const BoxedSectionEight = (props) => {
  const boxedSectionsFields = useSelector((state) =>
    extractFields(state, "boxSection", props.instance, 7)
  );

  useEffect(() => {
    window.carouselOne();
  }, []);

  return (
    // <!-- Section Two Start -->

    <div className="section_one_work_area">
      <div className="container">
        <div className="section_one_work">
          <div className="row">
            <div className="col-sm-12">
              <div className="section_one_sec_title">
                <h4>{boxedSectionsFields[0].value}</h4>
                <h2>{boxedSectionsFields[1].value}</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="section_one_slider_area">
                <div className="section_one_slider">
                  <div className="carousel_one">
                    <div className="section_one_single_slide text-center">
                      <div className="rating">
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                      </div>
                      <p>{boxedSectionsFields[3].value}</p>
                      <h4>{boxedSectionsFields[2].value}</h4>
                    </div>
                    <div className="section_one_single_slide text-center">
                      <div className="rating">
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                      </div>
                      <p>{boxedSectionsFields[5].value}</p>
                      <h4>{boxedSectionsFields[4].value}</h4>
                    </div>
                    <div className="section_one_single_slide text-center">
                      <div className="rating">
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                      </div>
                      <p>{boxedSectionsFields[7].value}</p>
                      <h4>{boxedSectionsFields[6].value}</h4>
                    </div>
                    {/* <div className="section_one_single_slide text-center">
                      <div className="rating">
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                      </div>
                      <p>
                        “Nick and Trevor did an awesome job rewriting an outlet
                        for our TV. They checked multiple options and took their
                        time to make sure I had all the details and options.
                        Will definitely use them again!”
                      </p>
                      <h4>Alex/Katelyn Loeffelman</h4>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BoxedSectionEight;
