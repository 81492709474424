import React from "react";
import { useSelector } from "react-redux";
import { extractFields } from "../../../utility";

export const BoxedSectionSeventeenFields = [
  {
    type: "string",
    label: "Title",
    value: "PRICING",
  },
  {
    type: "string",
    label: "Sub Title",
    value: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
  },
  {
    type: "string",
    label: "Package 1 Name",
    value: "Standard",
  },
  {
    type: "string",
    label: "Package 1 Price",
    value: "$99",
  },
  {
    type: "string",
    label: "Package 1 Feature 1",
    value: "Lift stains",
  },
  {
    type: "string",
    label: "Package 1 Feature 2",
    value: "Visible results in one session",
  },
  {
    type: "string",
    label: "Package 1 Feature 3",
    value: "State of the art technology",
  },
  {
    type: "string",
    label: "Package 1 Feature 4",
    value: "State of the art technology",
  },
  {
    type: "string",
    label: "Package 1 Feature 5",
    value: "State of the art technology",
  },
  {
    type: "button",
    label: ["Button 1", "Button 1 Link"],
    value: {
      text: "Book Now",
      link: "#",
    },
  },

  {
    type: "string",
    label: "Package 2 Name",
    value: "Standard",
  },
  {
    type: "string",
    label: "Package 2 Price",
    value: "$99",
  },
  {
    type: "string",
    label: "Package 2 Feature 1",
    value: "Lift stains",
  },
  {
    type: "string",
    label: "Package 2 Feature 2",
    value: "Visible results in one session",
  },
  {
    type: "string",
    label: "Package 2 Feature 3",
    value: "State of the art technology",
  },
  {
    type: "string",
    label: "Package 2 Feature 4",
    value: "State of the art technology",
  },
  {
    type: "string",
    label: "Package 2 Feature 5",
    value: "State of the art technology",
  },
  {
    type: "button",
    label: ["Button 2", "Button 2 Link"],
    value: {
      text: "Book Now",
      link: "#",
    },
  },
  {
    type: "string",
    label: "Package 3 Name",
    value: "Standard",
  },
  {
    type: "string",
    label: "Package 3 Price",
    value: "$99",
  },
  {
    type: "string",
    label: "Package 3 Feature 1",
    value: "Lift stains",
  },
  {
    type: "string",
    label: "Package 3 Feature 2",
    value: "Visible results in one session",
  },
  {
    type: "string",
    label: "Package 3 Feature 3",
    value: "State of the art technology",
  },
  {
    type: "string",
    label: "Package 3 Feature 4",
    value: "State of the art technology",
  },
  {
    type: "string",
    label: "Package 3 Feature 5",
    value: "State of the art technology",
  },
  {
    type: "button",
    label: ["Button 3", "Button 3 Link"],
    value: {
      text: "Book Now",
      link: "#",
    },
  },
];

const BoxedSectionSeventeen = (props) => {
  const boxedSectionsFields = useSelector((state) =>
    extractFields(state, "boxSection", props.instance, 16)
  );

  return (
    // <!-- Section Two Start -->
    <div className="boxed_sixteen_pricing_area">
      <div className="container">
        <div className="boxed_sixteen_pricing">
          <div className="row">
            <div className="col-sm-12">
              <div className="boxed_sixteen_sec_title_even">
                <h1>{boxedSectionsFields[0].value}</h1>
                <p>{boxedSectionsFields[1].value}</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="boxed_sixteen_pricing_table">
                {/* <!-- Single Pricing Table Start --> */}
                <div className="boxed_sixteen_single_table side pink">
                  <h3>{boxedSectionsFields[2].value}</h3>
                  <h1>{boxedSectionsFields[3].value}</h1>
                  <a
                    href={boxedSectionsFields[9].value.link}
                    className="boxed_sixteen_tablebtn"
                  >
                    {boxedSectionsFields[9].value.text}
                  </a>
                  <ul className="boxed_sixteen_table_list">
                    <li>
                      <i className="fa fa-check" aria-hidden="true"></i>{" "}
                      {boxedSectionsFields[4].value}
                    </li>
                    <li>
                      <i className="fa fa-check" aria-hidden="true"></i>{" "}
                      {boxedSectionsFields[5].value}
                    </li>
                    <li>
                      <i className="fa fa-check" aria-hidden="true"></i>{" "}
                      {boxedSectionsFields[6].value}
                    </li>
                    <li>
                      <i className="fa fa-check" aria-hidden="true"></i>{" "}
                      {boxedSectionsFields[7].value}
                    </li>
                    <li>
                      <i className="fa fa-check" aria-hidden="true"></i>{" "}
                      {boxedSectionsFields[8].value}
                    </li>
                  </ul>
                </div>
                {/* <!-- Single Pricing Table End --> */}

                {/* <!-- Single Pricing Table Start --> */}
                <div className="boxed_sixteen_single_table middle sky">
                  <h3>{boxedSectionsFields[10].value}</h3>
                  <h1>{boxedSectionsFields[11].value}</h1>
                  <a
                    href={boxedSectionsFields[17].value.link}
                    className="boxed_sixteen_tablebtn"
                  >
                    {boxedSectionsFields[17].value.text}
                  </a>
                  <ul className="boxed_sixteen_table_list">
                    <li>
                      <i className="fa fa-check" aria-hidden="true"></i>{" "}
                      {boxedSectionsFields[12].value}
                    </li>
                    <li>
                      <i className="fa fa-check" aria-hidden="true"></i>{" "}
                      {boxedSectionsFields[13].value}
                    </li>
                    <li>
                      <i className="fa fa-check" aria-hidden="true"></i>{" "}
                      {boxedSectionsFields[14].value}
                    </li>
                    <li>
                      <i className="fa fa-check" aria-hidden="true"></i>{" "}
                      {boxedSectionsFields[15].value}
                    </li>
                    <li>
                      <i className="fa fa-check" aria-hidden="true"></i>{" "}
                      {boxedSectionsFields[16].value}
                    </li>
                  </ul>
                </div>
                {/* <!-- Single Pricing Table End --> */}

                {/* <!-- Single Pricing Table Start --> */}
                <div className="boxed_sixteen_single_table side unknown">
                  <h3>{boxedSectionsFields[18].value}</h3>
                  <h1>{boxedSectionsFields[19].value}</h1>
                  <a
                    href={boxedSectionsFields[25].value.link}
                    className="boxed_sixteen_tablebtn"
                  >
                    {boxedSectionsFields[25].value.text}
                  </a>
                  <ul className="boxed_sixteen_table_list">
                    <li>
                      <i className="fa fa-check" aria-hidden="true"></i>{" "}
                      {boxedSectionsFields[20].value}
                    </li>
                    <li>
                      <i className="fa fa-check" aria-hidden="true"></i>{" "}
                      {boxedSectionsFields[21].value}
                    </li>
                    <li>
                      <i className="fa fa-check" aria-hidden="true"></i>{" "}
                      {boxedSectionsFields[22].value}
                    </li>
                    <li>
                      <i className="fa fa-check" aria-hidden="true"></i>{" "}
                      {boxedSectionsFields[23].value}
                    </li>
                    <li>
                      <i className="fa fa-check" aria-hidden="true"></i>{" "}
                      {boxedSectionsFields[24].value}
                    </li>
                  </ul>
                </div>
                {/* <!-- Single Pricing Table End --> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BoxedSectionSeventeen;
