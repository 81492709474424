import React from "react";
import { useSelector } from "react-redux";
import { extractFields } from "../../../utility";

export const TitleFourFields = [
  { type: "string", label: "Title", value: "catchy tagline" },
];

const TitleFour = (props) => {
  const titleFields = useSelector((state) =>
    extractFields(state, "title", props.instance, 3)
  );

  return (
    // <!-- Title Two Start -->
    <div className="section_title_four_area">
      <div className="container">
        <div className="section_title_four_inner">
          <div className="row">
            <div className="col-sm-12">
              <div className="section_title_four">
                <h1>{titleFields[0].value}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    // <!-- Title Two End -->
  );
};

export default TitleFour;
