import React from "react";
import { useSelector } from "react-redux";
import { extractFields } from "../../../utility";

export const BoxedSectionFourFields = [
  {
    type: "string",
    label: "Title",
    value: "ABOUT US",
  },
  {
    type: "string",
    label: "Sub Title",
    value: "SKYMARK PEST SOLUTIONS",
  },
  {
    type: "string",
    label: "Description",
    value:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque tellus eros, aliquam quis cursus in, tempor sed eros. Etiam hendrerit justo non velit pretium pulvinar vitae nec turpis. Nulla a mi dolor. Cras a nibh ligula. Suspendisse quis tellus mattis, lacinia eros a, porttitor metus. Curabitur placerat velit et purus volutpat cursus. Proin consequat dolor id facilisis blandit. In id viverra erat. Sed maximus dapibus metus, ac rutrum magna consequat nec. Donec maximus, metus condimentum.",
  },
  {
    type: "image",
    label: "Image",
    value: "",
    file: null,
    displayValue: "img/sparay-man.jpg",
  },
  {
    type: "button",
    label: ["Link text 1", "Link 1"],
    value: {
      text: "Learn more",
      link: "#",
    },
  },
];

const BoxedSectionFour = (props) => {
  const boxedSectionsFields = useSelector((state) =>
    extractFields(state, "boxSection", props.instance, 3)
  );

  return (
    // <!-- Section Two Start -->
    <div className="boxed_section_about_us_area">
      <div className="container">
        <div className="boxed_section_about_us">
          <div className="col-sm-6">
            <div className="boxed_section_single_about_us">
              <div className="boxed_section_about_us_img">
                <img src={boxedSectionsFields[3].displayValue} alt="" />
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="boxed_section_single_about_us">
              <h5>{boxedSectionsFields[0].value}</h5>
              <h2>{boxedSectionsFields[1].value}</h2>
              <p>{boxedSectionsFields[2].value}</p>
              <a
                href={boxedSectionsFields[4].value.link}
                className="btn boxed_section_learn_more_btn"
              >
                {boxedSectionsFields[4].value.text}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BoxedSectionFour;
