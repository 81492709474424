import React from "react";
import { useSelector } from "react-redux";
import { extractFields } from "../../../utility";

export const SectionEightFields = [
  {
    type: "string",
    label: "Title",
    value: "About Us",
  },
  {
    type: "string",
    label: "Description",
    value:
      "It is the mission of JBK Plumbers to provide friendly, professional, and efficient plumbing services in Utah County. Whether it’s an emergency sink repair in Saratoga Springs, a drain cleaning service in Alpine,  or a sewer line repiping in Lehi, our team aims to provide hassle-free assistance with a courteous approach at reasonable rates.",
  },
  {
    type: "image",
    label: "Background Image",
    value: "",
    file: null,
    displayValue: "img/s-one-about.jpg",
  },
];

const SectionEight = (props) => {
  const sectionFields = useSelector((state) =>
    extractFields(state, "section", props.instance, 7)
  );

  return (
    // <!-- Section Eight Start -->
    <div className="section_six_about_area">
      <div className="container">
        <div className="section_six_about">
          <div className="row">
            <div className="col-sm-6">
              <div className="ssection_six_ingle_about">
                <img src={sectionFields[2].displayValue} alt="" />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="section_six_single_about">
                <h2>{sectionFields[0].value}</h2>
                <p>{sectionFields[1].value}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    // <!-- Section Eight End -->
  );
};

export default SectionEight;
