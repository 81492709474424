import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { extractFields } from "../../../utility";

export const SectionSixteenFields = [
  {
    type: "string",
    label: "Client 1 Name",
    value: "Richard C.",
  },
  {
    type: "string",
    label: "Feedback 1",
    value:
      "“Kazmierski & Bowen Orthodontics provided gorgeous smiles for all of my children. We have loved our experience there and we look forward to returning for each appointment!”",
  },
  {
    type: "string",
    label: "Client 2 Name",
    value: "Richard C.",
  },
  {
    type: "string",
    label: "Feedback 2",
    value:
      "“Kazmierski & Bowen Orthodontics provided gorgeous smiles for all of my children. We have loved our experience there and we look forward to returning for each appointment!”",
  },
  {
    type: "image",
    label: "Background Image",
    value: "",
    file: null,
    displayValue: "img/Group-137.jpg",
  },
];

const SectionSixteen = (props) => {
  const sectionFields = useSelector((state) =>
    extractFields(state, "section", props.instance, 15)
  );

  useEffect(() => {
    window.sectionSixteen();
  }, []);

  const bgImage =
    "url(" +
    sectionFields[4].displayValue.replace(/^"(.+(?="$))"$/, "$1") +
    ")";

  return (
    // <!-- Section Eight Start -->
    <div
      style={{ backgroundImage: bgImage }}
      className="section_sixteen_testimonial_area"
    >
      <div className="container">
        <div className="section_sixteen_testimonial">
          <div className="row">
            <div className="col-sm-12">
              <div className="section_sixteen_carousel">
                <div className="section_sixteen_single_testimonial">
                  <h3>{sectionFields[1].value}</h3>
                  <span>-{sectionFields[0].value}</span>
                </div>
                <div className="section_sixteen_single_testimonial">
                  <h3>{sectionFields[3].value}</h3>
                  <span>-{sectionFields[2].value}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    // <!-- Section Eight End -->
  );
};

export default SectionSixteen;
