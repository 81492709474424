import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { extractFields } from "../../../utility";

// import OwlCarousel from "react-owl-carousel";
// import "owl.carousel/dist/assets/owl.carousel.css";
// import "owl.carousel/dist/assets/owl.theme.default.css";

export const SectionTenFields = [
  {
    type: "string",
    label: "Title",
    value: "what clients say",
  },
  {
    type: "string",
    label: "Client 1 Name",
    value: "Client 1",
  },
  {
    type: "string",
    label: "Feedback 1",
    value:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi sit amet augue non felis consectetur tristique non eget ligula. Phasellus ullamcorper tincidunt molestie. Pellentesque eu velit scelerisque, interdum nisl a, auctor lectus.",
  },
  {
    type: "string",
    label: "Client 2 Name",
    value: "Client 2",
  },
  {
    type: "string",
    label: "Feedback 2",
    value:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi sit amet augue non felis consectetur tristique non eget ligula. Phasellus ullamcorper tincidunt molestie. Pellentesque eu velit scelerisque, interdum nisl a, auctor lectus.",
  },
  {
    type: "string",
    label: "Client 3 Name",
    value: "Client 3",
  },
  {
    type: "string",
    label: "Feedback 3",
    value:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi sit amet augue non felis consectetur tristique non eget ligula. Phasellus ullamcorper tincidunt molestie. Pellentesque eu velit scelerisque, interdum nisl a, auctor lectus.",
  },
];

const SectionTen = (props) => {
  const sectionFields = useSelector((state) =>
    extractFields(state, "section", props.instance, 9)
  );


  useEffect(() => {
    window.sectionTen();
  }, []);

  return (
    <>
      {/* // <!-- Section Eight Start --> */}
      <div className="section_ten_testimonial_area">
        <div className="container">
          <div className="section_ten_testimonial">
            <div className="row">
              <div className="col-sm-12">
                <div className="title_div">
                  <h2 className="sec_title_two">{sectionFields[0].value}</h2>
                </div>

                <div className="section_ten_testimonial_carosel">
                {/* <OwlCarousel
                  margin={0}
                  nav={true}
                  navRewind={true}
                  dots={true}
                  // navText={[
                  //   '<i className="fa fa-angle-left"></i>',
                  //   '<i className="fa fa-angle-right"></i>',
                  // ]}
                  items={1}
                  loop={true}
                  mouseDrag={true}
                  autoplay={true}
                  smartSpeed={600}
                  autoplayTimeout={10000}
                > */}
                <div className="item section_ten_single_carosel">
                  <p>{sectionFields[2].value}</p>
                  <h4>{sectionFields[1].value}</h4>
                </div>
                <div className="item section_ten_single_carosel">
                  <p>{sectionFields[4].value}</p>
                  <h4>{sectionFields[3].value}</h4>
                </div>
                <div className="item section_ten_single_carosel">
                  <p>{sectionFields[6].value}</p>
                  <h4>{sectionFields[5].value}</h4>
                </div>
                {/* </OwlCarousel> */}
              </div>
            </div>
              </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SectionTen;
