import getData from "../../../data/data";

const getInitialColors = () => {
  return {
    mainColor: "#0984e3",
    mainTextColor: "#ffffff",
    secondaryColor: "#000",
    backgroundColor: "#f0fff0",
  };
};

export const getInitialSettings = (type) => {
  switch (type) {
    case "header":
      return { settings1List: [0, 1, 2], settings2List: [-1] };
    case "banner":
      return { settings1List: [0, 1, 2], settings2List: [-1] };
    case "boxSection":
      return {
        settings1List: [
          0,
          1,
          2,
          3,
          4,
          5,
          6,
          7,
          8,
          9,
          10,
          11,
          12,
          13,
          14,
          15,
          16,
        ],
        settings2List: [-1],
      };
    case "section":
      return {
        settings1List: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        settings2List: [-1],
      };
    case "title":
      return { settings1List: [0, 1, 2, 3], settings2List: [-1] };
    case "footer":
      return { settings1List: [0, 1, 2], settings2List: [-1] };
    default:
      return null;
  }
};

const initialState = {
  sections: [
    {
      type: "header",
      activeLayout: 0,
      instance: 0,
      fields: getData().header,
      sticky: false,
      colorSettings: {},
      colorScheme: { settings1List: [0, 1, 2], settings2List: [-1] },
      // numberOfSections: 3,
    },
    {
      type: "banner",
      activeLayout: 0,
      instance: 0,
      fields: getData().banner,
      colorSettings: {},
      colorScheme: { settings1List: [0, 1, 2], settings2List: [-1] },
      // numberOfSections: 3,
    },
    {
      type: "boxSection",
      activeLayout: 5,
      instance: 0,
      fields: getData().boxSection,
      colorSettings: {},
      colorScheme: {
        settings1List: [
          0,
          1,
          2,
          3,
          4,
          5,
          6,
          7,
          8,
          9,
          10,
          11,
          12,
          13,
          14,
          15,
          16,
        ],
        settings2List: [-1],
      },
      // numberOfSections: 16,
    },
    {
      type: "section",
      activeLayout: 9,
      instance: 0,
      fields: getData().section,
      colorSettings: {},
      colorScheme: {
        settings1List: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        settings2List: [-1],
      },
      // numberOfSections: 17,
    },
    {
      type: "title",
      activeLayout: 0,
      instance: 0,
      fields: getData().title,
      colorSettings: {},
      colorScheme: { settings1List: [0, 1, 2, 3], settings2List: [-1] },
      // numberOfSections: 4,
    },
    {
      type: "footer",
      activeLayout: 0,
      instance: 0,
      fields: getData().footer,
      colorSettings: {},
      colorScheme: { settings1List: [0, 1, 2], settings2List: [-1] },
      // numberOfSections: 3,
    },
  ],
  numberOfSections: {
    header: 3,
    banner: 3,
    section: 17,
    boxSection: 17,
    title: 4,
    footer: 3,
  },
  previewUrl: "",
  mainColor: {
    one: { checked: true, colors: getInitialColors() },
    two: { checked: false, colors: getInitialColors() },
  },
  updateDB: 0,
};

export default initialState;
