import React from "react";
import { useSelector } from "react-redux";
import { extractFields } from "../../../utility";

export const FooterThreeFields = [
  {
    type: "string",
    label: "Address 1 Line 1",
    value: "headquarters",
  },
  {
    type: "string",
    label: "Address 1 Line 2",
    value: "1050 Wilshire Drive, Suite 170",
  },
  {
    type: "string",
    label: "Address 1 Line 3",
    value: "Troy, MI 48084",
  },
  {
    type: "string",
    label: "Address 1 Line 1",
    value: "headquarters",
  },
  {
    type: "string",
    label: "Address 1 Line 2",
    value: "1050 Wilshire Drive, Suite 170",
  },
  {
    type: "string",
    label: "Address 1 Line 3",
    value: "Troy, MI 48084",
  },
  {
    type: "string",
    label: "Address 1 Line 1",
    value: "headquarters",
  },
  {
    type: "string",
    label: "Address 1 Line 2",
    value: "1050 Wilshire Drive, Suite 170",
  },
  {
    type: "string",
    label: "Address 1 Line 3",
    value: "Troy, MI 48084",
  },
  {
    type: "string",
    label: "Title",
    value: "Get into Touch",
  },
  {
    type: "string",
    label: "Description",
    textare: true,
    value:
      "Centroid is a cloud services technology company and Oracle Platinum-level partner, offering IT consulting, managed cloud services, and cloud solutions. Our services span the entire enterprise. From applications to technology to infrastructure, Centroid’s end-to-end capabilities help clients modernize, transform, and grow their businesses.",
  },
  {
    type: "string",
    label: "Footer text",
    value: "©2018 Centroid, Inc. All rights reserved.",
  },
  {
    type: "button",
    label: ["Link 1 text", "Link 1 link"],
    value: {
      text: "Privacy Policy",
      link: "#",
    },
  },
  {
    type: "image",
    label: "Logo",
    value: "",
    file: null,
    displayValue: "img/footer-logo-one.png",
  },
];

const FooterThree = (props) => {
  const footerFields = useSelector((state) =>
    extractFields(state, "footer", props.instance, 2)
  );

  return (
    <div className="footer_area_one">
      <div className="container">
        <div className="footer_one">
          <div className="row">
            <div className="col-sm-7">
              <div className="footer_widget_one">
                <div className="footer_logo_one">
                  <a href="">
                    <img src={footerFields[13].displayValue} alt="" />
                  </a>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="single_widget_one">
                      <h4>{footerFields[0].value}</h4>
                      <p>{footerFields[1].value}</p>
                      <p>{footerFields[2].value}</p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="single_widget_one">
                      <h4>{footerFields[3].value}</h4>
                      <p>{footerFields[4].value}</p>
                      <p>{footerFields[5].value}</p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="single_widget_one">
                      <h4>{footerFields[6].value}</h4>
                      <p>{footerFields[7].value}</p>
                      <p>{footerFields[8].value}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-5">
              <div className="single_widget_right_one">
                <h4> {footerFields[9].value}</h4>
                <p>{footerFields[10].value}</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="footer_bottom_one">
                <p> {footerFields[11].value}</p>
                <p>
                  <a href={footerFields[12].value.link}>
                    {" "}
                    {footerFields[12].value.text}
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterThree;
