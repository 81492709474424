import React from "react";
import { useSelector } from "react-redux";
import { extractFields } from "../../../utility";

export const SectionFourFields = [
  {
    type: "image",
    label: "Image 1 ",
    value: "",
    file: null,
    displayValue: "img/assurance-icon-1.png",
  },
  {
    type: "image",
    label: "Image 2",
    value: "",
    file: null,
    displayValue: "img/assurance-icon-2.png",
  },
  {
    type: "image",
    label: "Image 3",
    value: "",
    file: null,
    displayValue: "img/assurance-icon-3.png",
  },
  {
    type: "image",
    label: "Image 4",
    value: "",
    file: null,
    displayValue: "img/assurance-icon-4.png",
  },
  {
    type: "image",
    label: "Image 5",
    value: "",
    file: null,
    displayValue: "img/assurance-icon-5.png",
  },
];

const SectionFour = (props) => {
  const sectionFields = useSelector((state) =>
    extractFields(state, "section", props.instance, 3)
  );

  return (
    <div className="section_one_assurance_area">
      <div className="container">
        <div className="electric_assurance">
          <div className="row">
            <div className="col-sm-12">
              <div className="electric_assurance_inner">
                <ul className="assurance_list">
                  {sectionFields.map((f, idx) => (
                    <li key={idx}>
                      <img src={f.displayValue} alt="" />
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionFour;
