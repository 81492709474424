import React from "react";
import { useSelector } from "react-redux";
import { extractFields } from "../../../utility";

export const SectionThreeFields = [
  {
    type: "string",
    label: "Title",
    value: "Specialists In Modern Lighting & Electric",
  },
  {
    type: "string",
    label: "Sub Title",
    value:
      "TruTec Electric is a locally owned and operated company specializing in residential service, repair and installation.",
  },
  {
    type: "string",
    label: "Icon 1 text",
    value: "Upfront Pricing",
  },
  {
    type: "string",
    label: "Icon 1 description",
    value: "We provide honest pricing so you know exactly what you pay for.",
  },
  {
    type: "image",
    label: "Icon 1",
    value: "",
    file: null,
    displayValue: "img/ele-icon-1.png",
  },
  {
    type: "string",
    label: "Icon 2 text",
    value: "Licenced Electricians",
  },
  {
    type: "string",
    label: "Icon 2 description",
    value: "We provide honest pricing so you know exactly what you pay for.",
  },
  {
    type: "image",
    label: "Icon 2",
    value: "",
    file: null,
    displayValue: "img/ele-icon-2.png",
  },
  {
    type: "string",
    label: "Icon 3 text",
    value: "Industry Leading Warranties",
  },
  {
    type: "string",
    label: "Icon 3 description",
    value: ">We provide honest pricing so you know exactly what you pay for.",
  },
  {
    type: "image",
    label: "Icon 3",
    value: "",
    file: null,
    displayValue: "img/ele-icon-3.png",
  },
  {
    type: "string",
    label: "Icon 4 text",
    value: "Licenced Electricians",
  },
  {
    type: "string",
    label: "Icon 4 description",
    value: "We provide honest pricing so you know exactly what you pay for.",
  },
  {
    type: "image",
    label: "Icon 4",
    value: "",
    file: null,
    displayValue: "img/ele-icon-4.png",
  },
  {
    type: "image",
    label: "Center Image",
    value: "",
    file: null,
    displayValue: "img/trutec-man.jpg",
  },
  {
    type: "button",
    label: ["Button 1", "Button 1 Link"],
    value: {
      text: "Contact Us Today",
      link: "#",
    },
  },
];

const SectionThree = (props) => {
  const sectionFields = useSelector((state) =>
    extractFields(state, "section", props.instance, 2)
  );

  return (
    <>
      {/* // <!-- Section One Start --> */}
      <div className="electric_area">
        <div className="container">
          <div className="electric">
            <div className="row">
              <div className="col-sm-12">
                <div className="section_one_sec_title">
                  <h2>{sectionFields[0].value}</h2>
                  <p>{sectionFields[1].value}</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-4">
                <div className="single_electric electric_top text-right">
                  <img src={sectionFields[4].displayValue} alt="" />
                  <h4>{sectionFields[2].value}</h4>
                  <p>{sectionFields[3].value}</p>
                </div>
                <div className="single_electric electric_bottom text-right">
                  <img src={sectionFields[7].displayValue} alt="" />
                  <h4>{sectionFields[5].value}</h4>
                  <p>{sectionFields[6].value}</p>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="single_electric electric_middle text-center">
                  <img src={sectionFields[14].displayValue} alt="" />
                </div>
              </div>
              <div className="col-sm-4">
                <div className="single_electric electric_top text-left">
                  <img src={sectionFields[10].displayValue} alt="" />
                  <h4>{sectionFields[8].value}</h4>
                  <p>{sectionFields[9].value}</p>
                </div>
                <div className="single_electric electric_bottom text-left">
                  <img src={sectionFields[13].displayValue} alt="" />
                  <h4>{sectionFields[11].value}</h4>
                  <p>{sectionFields[12].value}</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="section_one_button_sec">
                  <a
                    href={sectionFields[15].value.link}
                    className="btn electric_btn"
                  >
                    {sectionFields[15].value.text}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Section One End --> */}
    </>
  );
};

export default SectionThree;
