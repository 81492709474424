import React from "react";
import { useSelector } from "react-redux";
import { extractFields } from "../../../utility";

export const BoxedSectionThirteenFields = [
  {
    type: "string",
    label: "Title",
    value: "Recents News",
  },
  {
    type: "image",
    label: "Image 1",
    value: "",
    file: null,
    displayValue: "img/box-testi.jpg",
  },
  {
    type: "string",
    label: "Title 1",
    value: "How I Prepare For Spring With Rosacea",
  },
  {
    type: "string",
    label: "Sub Title 1",
    textare: true,
    value: "Jul 26, 2020",
  },
  {
    type: "image",
    label: "Image 2",
    value: "",
    file: null,
    displayValue: "img/box-testi.jpg",
  },
  {
    type: "string",
    label: "Title 2",
    value: "How I Prepare For Spring With Rosacea",
  },
  {
    type: "string",
    label: "Sub Title 2",
    textare: true,
    value: "Jul 26, 2020",
  },

  {
    type: "image",
    label: "Image 3",
    value: "",
    file: null,
    displayValue: "img/box-testi.jpg",
  },
  {
    type: "string",
    label: "Title 3",
    value: "How I Prepare For Spring With Rosacea",
  },
  {
    type: "string",
    label: "Sub Title 3",
    textare: true,
    value: "Jul 26, 2020",
  },
  {
    type: "button",
    label: ["Link text", "Link"],
    value: {
      text: "See All News",
      link: "#",
    },
  },
];

const BoxedSectionThirteen = (props) => {
  const boxedSectionsFields = useSelector((state) =>
    extractFields(state, "boxSection", props.instance, 12)
  );

  return (
    // <!-- Section Two Start -->

    <div className="boxed_eleven_news_area">
      <div className="container">
        <div className="boxed_eleven_news">
          <div className="row">
            <div className="col-sm-12">
              <h1 className="boxed_eleven_news_title">
                {boxedSectionsFields[0].value}
              </h1>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-4">
              <div className="boxed_eleven_single_news">
                <img src={boxedSectionsFields[1].displayValue} alt="" />
                <h4>{boxedSectionsFields[2].value}</h4>
                <p>{boxedSectionsFields[3].value}</p>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="boxed_eleven_single_news">
                <img src={boxedSectionsFields[4].displayValue} alt="" />
                <h4>{boxedSectionsFields[5].value}</h4>
                <p>{boxedSectionsFields[6].value}</p>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="boxed_eleven_single_news">
                <img src={boxedSectionsFields[7].displayValue} alt="" />
                <h4>{boxedSectionsFields[8].value}</h4>
                <p>{boxedSectionsFields[9].value}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 text-center">
                <a
                  href={boxedSectionsFields[10].value.link}
                  className="button boxed_eleven_news_btn"
                >
                  {boxedSectionsFields[10].value.text}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BoxedSectionThirteen;
