export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties,
  };
};

export const checkValidity = (value, rules, shouldMatchValue) => {
  let isValid = true;
  if (!rules) {
    return true;
  }
  if (rules.required) {
    isValid = value.trim() !== "" && isValid;
  }
  if (rules.minLength) {
    isValid = value.length >= rules.minLength && isValid;
  }

  if (rules.isEmail) {
    const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    isValid = pattern.test(value) && isValid;
  }
  if (shouldMatchValue) {
    isValid = value === shouldMatchValue && isValid;
  }
  // if(rules.shouldMatchPassword){
  //   isValid = value === this.state.controls.password.value && isValid
  // }

  return isValid;
};

export const extractFields = (state, type, instance, layout) => {
  return state.layout.sections.filter(
    (s) => s.type === type && s.instance === instance
  )[0].fields[layout];
};
