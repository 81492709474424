import React from "react";
import { useSelector } from "react-redux";
import { extractFields } from "../../../utility";
export const BoxedSectionTwelveFields = [
  {
    type: "image",
    label: "Background Image 1",
    value: "",
    file: null,
    displayValue: "img/team-1.jpg",
  },
  {
    type: "string",
    label: "Title 1",
    value: "Custom Fabrication",
  },
  {
    type: "string",
    label: "Sub Title  1",
    textare: true,
    value:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ut leo tempor, dictum nisi vitae, blandit risus. Morbi euismod justo ligula, vel dictum mauris maximus eget. Etiam nec nisi nec lectus commodo egestas. Etiam nec nisi nec lectus commodo egestas.",
  },
  {
    type: "image",
    label: "Background Image 2",
    value: "",
    file: null,
    displayValue: "img/team-2.jpg",
  },
  {
    type: "string",
    label: "Title 2",
    value: "Custom Fabrication",
  },
  {
    type: "string",
    label: "Sub Title  2",
    textare: true,
    value:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ut leo tempor, dictum nisi vitae, blandit risus. Morbi euismod justo ligula, vel dictum mauris maximus eget. Etiam nec nisi nec lectus commodo egestas. Etiam nec nisi nec lectus commodo egestas.",
  },

  {
    type: "image",
    label: "Background Image 3",
    value: "",
    file: null,
    displayValue: "img/team-3.jpg",
  },
  {
    type: "string",
    label: "Title 3",
    value: "Custom Fabrication",
  },
  {
    type: "string",
    label: "Sub Title  3",
    textare: true,
    value:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ut leo tempor, dictum nisi vitae, blandit risus. Morbi euismod justo ligula, vel dictum mauris maximus eget. Etiam nec nisi nec lectus commodo egestas. Etiam nec nisi nec lectus commodo egestas.",
  },
];

const BoxedSectionTwelve = (props) => {
  const boxedSectionsFields = useSelector((state) =>
    extractFields(state, "boxSection", props.instance, 11)
  );
  const bgImage1 =
    "url(" +
    boxedSectionsFields[0].displayValue.replace(/^"(.+(?="$))"$/, "$1") +
    ")";
  const bgImage2 =
    "url(" +
    boxedSectionsFields[3].displayValue.replace(/^"(.+(?="$))"$/, "$1") +
    ")";
  const bgImage3 =
    "url(" +
    boxedSectionsFields[6].displayValue.replace(/^"(.+(?="$))"$/, "$1") +
    ")";

  return (
    // <!-- Section Two Start -->

    <div className="boxed_ten_team_area">
      <div
        style={{ backgroundImage: bgImage1 }}
        className="boxed_ten_single_team boxed_ten_first"
      >
        <div className="boxed_ten_inner_text">
          <h3>{boxedSectionsFields[1].value}</h3>
          <p>{boxedSectionsFields[2].value}</p>
        </div>
      </div>
      <div
        style={{ backgroundImage: bgImage2 }}
        className="boxed_ten_single_team boxed_ten_middle"
      >
        <div className="boxed_ten_inner_text">
          <h3>{boxedSectionsFields[4].value}</h3>
          <p>{boxedSectionsFields[5].value}</p>
        </div>
      </div>
      <div
        style={{ backgroundImage: bgImage3 }}
        className="boxed_ten_single_team boxed_ten_last"
      >
        <div className="boxed_ten_inner_text">
          <h3>{boxedSectionsFields[7].value}</h3>
          <p>{boxedSectionsFields[8].value}</p>
        </div>
      </div>
    </div>
  );
};

export default BoxedSectionTwelve;
