import React from "react";
import { useSelector } from "react-redux";
import { extractFields } from "../../../utility";

export const BoxedSectionSixteenFields = [
  {
    type: "image",
    label: "Background Image",
    value: "",
    file: null,
    displayValue: "img/customer-bg.jpg",
  },
  {
    type: "string",
    label: "Main Title 1",
    value: "651+",
  },

  {
    type: "string",
    label: "Main Title 2",
    value: "Happy Customers",
  },
  {
    type: "string",
    label: "Main Description",
    value: "What are they Saying?",
  },
  {
    type: "string",
    label: "Title 1",
    value: "Mahnoor Tariq",
  },
  {
    type: "string",
    label: "Description 1",
    value:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque tellus eros, aliquam quis cursus in, tempor sed eros. Etiam hendrerit justo non velit pretium pulvinar vitae nec turpis. Nulla a mi dolor. Cras a nibh ligula. Suspendisse quis tellus mattis, lacinia eros a, porttitor metus. Curabitur placerat velit et purus volutpat cursus. Proin consequat dolor id facilisis blandit. In id viverra erat. Sed maximus dapibus metus, ac rutrum magna consequat nec. Donec maximus, metus condimentum.",
  },
  {
    type: "image",
    label: "Icon 1",
    value: "",
    file: null,
    displayValue: "img/people.png",
  },
  {
    type: "string",
    label: "Title 2",
    value: "24/7 Availability",
  },
  {
    type: "string",
    label: "Description 2",
    value:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque tellus eros, aliquam quis cursus in, tempor sed eros. Etiam hendrerit justo non velit pretium pulvinar vitae nec turpis. Nulla a mi dolor. Cras a nibh ligula. Suspendisse quis tellus mattis, lacinia eros a, porttitor metus. Curabitur placerat velit et purus volutpat cursus. Proin consequat dolor id facilisis blandit. In id viverra erat. Sed maximus dapibus metus, ac rutrum magna consequat nec. Donec maximus, metus condimentum.",
  },
  {
    type: "image",
    label: "Icon 2",
    value: "",
    file: null,
    displayValue: "img/people.png",
  },
  {
    type: "string",
    label: "Title 3",
    value: "24/7 Availability",
  },
  {
    type: "string",
    label: "Description 3",
    value:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque tellus eros, aliquam quis cursus in, tempor sed eros. Etiam hendrerit justo non velit pretium pulvinar vitae nec turpis. Nulla a mi dolor. Cras a nibh ligula. Suspendisse quis tellus mattis, lacinia eros a, porttitor metus. Curabitur placerat velit et purus volutpat cursus. Proin consequat dolor id facilisis blandit. In id viverra erat. Sed maximus dapibus metus, ac rutrum magna consequat nec. Donec maximus, metus condimentum.",
  },
  {
    type: "image",
    label: "Icon 3",
    value: "",
    file: null,
    displayValue: "img/people.png",
  },
  {
    type: "string",
    label: "Title 4",
    value: "24/7 Availability",
  },
  {
    type: "string",
    label: "Description 4",
    value:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque tellus eros, aliquam quis cursus in, tempor sed eros. Etiam hendrerit justo non velit pretium pulvinar vitae nec turpis. Nulla a mi dolor. Cras a nibh ligula. Suspendisse quis tellus mattis, lacinia eros a, porttitor metus. Curabitur placerat velit et purus volutpat cursus. Proin consequat dolor id facilisis blandit. In id viverra erat. Sed maximus dapibus metus, ac rutrum magna consequat nec. Donec maximus, metus condimentum.",
  },
  {
    type: "image",
    label: "Icon 4",
    value: "",
    file: null,
    displayValue: "img/people.png",
  },
];

const BoxedSectionSixteen = (props) => {
  const boxedSectionFields = useSelector((state) =>
    extractFields(state, "boxSection", props.instance, 15)
  );

  const bgImage =
    "url(" +
    boxedSectionFields[0].displayValue.replace(/^"(.+(?="$))"$/, "$1") +
    ")";

  return (
    // <!-- Section Two Start -->

    <div
      style={{ backgroundImage: bgImage }}
      className="boxed_fourteen_customer_area"
    >
      <div className="container">
        <div className="boxed_fourteen_customer">
          <div className="row">
            <div className="col-sm-12">
              <div className="boxed_fourteen_customer_title">
                <h1>{boxedSectionFields[1].value}</h1>
                <h2>{boxedSectionFields[2].value}</h2>
                <h3>{boxedSectionFields[3].value}</h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-4">
              <div className="boxed_fourteen_single_customer">
                <img src={boxedSectionFields[6].displayValue} alt="" />
                <h3>{boxedSectionFields[4].value}</h3>
                <p>{boxedSectionFields[5].value}</p>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="boxed_fourteen_single_customer">
                <img src={boxedSectionFields[9].displayValue} alt="" />
                <h3>{boxedSectionFields[7].value}</h3>
                <p>{boxedSectionFields[8].value}</p>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="boxed_fourteen_single_customer">
                <img src={boxedSectionFields[12].displayValue} alt="" />
                <h3>{boxedSectionFields[10].value}</h3>
                <p>{boxedSectionFields[11].value}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BoxedSectionSixteen;
