import {
  LOGIN_START,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  CHECKING_AUTH_STATE,
  LOGOUT,
} from "../../actionTypes";
import { updateObject } from "../../../utility";

const initialState = {
  loading: false,
  success: false,
  error: "",
  user: null,
  checking_auth_state: false,
  checking_auth_state: true,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_START:
      return updateObject(state, { loading: true, success: false, error: "" });
    case LOGIN_SUCCESS:
      return updateObject(state, {
        loading: false,
        success: true,
        user: action.user,
      });
    case LOGIN_FAIL:
      return updateObject(state, { loading: false, error: action.error });
    case CHECKING_AUTH_STATE:
      return {
        ...state,
        checking_auth_state: action.checked,
      };
    case LOGOUT:
      return updateObject(state, initialState);
    default:
      return state;
  }
};

export default authReducer;
