import React from "react";
import { useSelector } from "react-redux";
import { extractFields } from "../../../utility";

export const BoxedSectionThreeFields = [
  {
    type: "string",
    label: "Title",
    value: "HOW CAN WE PROTECT YOUR HOME?",
  },
  {
    type: "string",
    label: "Box 1 Title",
    value: "Pest Control",
  },
  {
    type: "image",
    label: "Box 1 Image",
    value: "",
    file: null,
    displayValue: "img/insect-bg-1.jpg",
  },
  {
    type: "string",
    label: "Box 1 Overlay Title",
    value: "Termite Control",
  },
  {
    type: "string",
    label: "Box 1 Overlay Description",
    value:
      "Rhasellus at odio mattis, suscipit purus cursus, pellentesque nunc. Proin ac dictum orcdrerit augue.",
  },
  {
    type: "image",
    label: "Box 1 Overlay Image",
    value: "",
    file: null,
    displayValue: "img/insect-icon-1.png",
  },
  {
    type: "string",
    label: "Box 2 Title",
    value: "Pest Control",
  },
  {
    type: "image",
    label: "Box 2 Image",
    value: "",
    file: null,
    displayValue: "img/insect-bg-2.jpg",
  },
  {
    type: "string",
    label: "Box 2 Overlay Title",
    value: "Termite Control",
  },
  {
    type: "string",
    label: "Box 2 Overlay Description",
    value:
      "Rhasellus at odio mattis, suscipit purus cursus, pellentesque nunc. Proin ac dictum orcdrerit augue.",
  },
  {
    type: "image",
    label: "Box 2 Overlay Image",
    value: "",
    file: null,
    displayValue: "img/insect-icon-2.png",
  },
  {
    type: "string",
    label: "Box 3 Title",
    value: "Pest Control",
  },
  {
    type: "image",
    label: "Box 3 Image",
    value: "",
    file: null,
    displayValue: "img/insect-bg-3.jpg",
  },
  {
    type: "string",
    label: "Box 3 Overlay Title",
    value: "Termite Control",
  },
  {
    type: "string",
    label: "Box 3 Overlay Description",
    value:
      "Rhasellus at odio mattis, suscipit purus cursus, pellentesque nunc. Proin ac dictum orcdrerit augue.",
  },
  {
    type: "image",
    label: "Box 3 Overlay Image",
    value: "",
    file: null,
    displayValue: "img/insect-icon-3.png",
  },
  {
    type: "string",
    label: "Box 4 Title",
    value: "Pest Control",
  },
  {
    type: "image",
    label: "Box 4 Image",
    value: "",
    file: null,
    displayValue: "img/insect-bg-4.jpg",
  },
  {
    type: "string",
    label: "Box 4 Overlay Title",
    value: "Termite Control",
  },
  {
    type: "string",
    label: "Box 4 Overlay Description",
    value:
      "Rhasellus at odio mattis, suscipit purus cursus, pellentesque nunc. Proin ac dictum orcdrerit augue.",
  },
  {
    type: "image",
    label: "Box 4 Overlay Image",
    value: "",
    file: null,
    displayValue: "img/insect-icon-4.png",
  },
  {
    type: "string",
    label: "Box 5 Title",
    value: "Pest Control",
  },
  {
    type: "image",
    label: "Box 5 Image",
    value: "",
    file: null,
    displayValue: "img/insect-bg-5.jpg",
  },
  {
    type: "string",
    label: "Box 5 Overlay Title",
    value: "Termite Control",
  },
  {
    type: "string",
    label: "Box 5 Overlay Description",
    value:
      "Rhasellus at odio mattis, suscipit purus cursus, pellentesque nunc. Proin ac dictum orcdrerit augue.",
  },
  {
    type: "image",
    label: "Box 5 Overlay Image",
    value: "",
    file: null,
    displayValue: "img/insect-icon-5.png",
  },
  {
    type: "string",
    label: "Box 6 Title",
    value: "Pest Control",
  },
  {
    type: "image",
    label: "Box 6 Image",
    value: "",
    file: null,
    displayValue: "img/insect-bg-6.jpg",
  },
  {
    type: "string",
    label: "Box 6 Overlay Title",
    value: "Termite Control",
  },
  {
    type: "string",
    label: "Box 6 Overlay Description",
    value:
      "Rhasellus at odio mattis, suscipit purus cursus, pellentesque nunc. Proin ac dictum orcdrerit augue.",
  },
  {
    type: "image",
    label: "Box 6 Overlay Image",
    value: "",
    file: null,
    displayValue: "img/insect-icon-6.png",
  },
];

const BoxedSectionThree = (props) => {
  const boxedSectionsFields = useSelector((state) =>
    extractFields(state, "boxSection", props.instance, 2)
  );

  return (
    // <!-- Section Two Start -->
    <div className="boxed_section_one_area">
      <div className="container">
        <div className="boxed_section_one">
          <div className="row">
            <div className="col-sm-12">
              <div className="boxed_section_sec_title">
                <h2>{boxedSectionsFields[0].value}</h2>
              </div>
            </div>
          </div>
          <div className="row">
            {[0, 1, 2, 3, 4, 5].map((index) => {
              let idx = index * 5;
              return (
                <div key={idx} className="col-sm-6 col-md-4">
                  <div className="boxed_section_single_service">
                    <img
                      className="img-responsive"
                      src={boxedSectionsFields[idx + 2].displayValue}
                      alt=""
                    />
                    <h4>{boxedSectionsFields[idx + 1].value}</h4>
                    <div className="boxed_section_before_overlay_img">
                      <div className="boxed_section_half_circle"></div>
                      <img
                        src={boxedSectionsFields[idx + 5].displayValue}
                        alt=""
                      />
                    </div>
                    <div className="boxed_section_after_overlay">
                      <img
                        src={boxedSectionsFields[idx + 5].displayValue}
                        alt=""
                      />
                      <h4>{boxedSectionsFields[idx + 3].value}</h4>
                      <p>{boxedSectionsFields[idx + 4].value}</p>
                    </div>
                  </div>
                </div>
              );
            })}
            {/* <div className="col-sm-6 col-md-4">
              <div className="boxed_section_single_service">
                <img className="img-responsive" src="img/insect-bg-2.jpg" alt="" />
                <h4>Pest Control</h4>
                <div className="boxed_section_before_overlay_img">
                  <div className="boxed_section_half_circle"></div>
                  <img src="img/insect-icon-2.png" alt="" />
                </div>
                <div className="boxed_section_after_overlay">
                  <img src="img/insect-icon-2.png" alt="" />
                  <h4>Termite Control</h4>
                  <p>
                    Rhasellus at odio mattis, suscipit purus cursus,
                    pellentesque nunc. Proin ac dictum orcdrerit augue.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-4">
              <div className="boxed_section_single_service">
                <img className="img-responsive" src="img/insect-bg-3.jpg" alt="" />
                <h4>Pest Control</h4>
                <div className="boxed_section_before_overlay_img">
                  <div className="boxed_section_half_circle"></div>
                  <img src="img/insect-icon-3.png" alt="" />
                </div>
                <div className="boxed_section_after_overlay">
                  <img src="img/insect-icon-3.png" alt="" />
                  <h4>Termite Control</h4>
                  <p>
                    Rhasellus at odio mattis, suscipit purus cursus,
                    pellentesque nunc. Proin ac dictum orcdrerit augue.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-4">
              <div className="boxed_section_single_service">
                <img className="img-responsive" src="img/insect-bg-4.jpg" alt="" />
                <h4>Pest Control</h4>
                <div className="boxed_section_before_overlay_img">
                  <div className="boxed_section_half_circle"></div>
                  <img src="img/insect-icon-4.png" alt="" />
                </div>
                <div className="boxed_section_after_overlay">
                  <img src="img/insect-icon-4.png" alt="" />
                  <h4>Termite Control</h4>
                  <p>
                    Rhasellus at odio mattis, suscipit purus cursus,
                    pellentesque nunc. Proin ac dictum orcdrerit augue.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-4">
              <div className="boxed_section_single_service">
                <img className="img-responsive" src="img/insect-bg-5.jpg" alt="" />
                <h4>Pest Control</h4>
                <div className="boxed_section_before_overlay_img">
                  <div className="boxed_section_half_circle"></div>
                  <img src="img/insect-icon-5.png" alt="" />
                </div>
                <div className="boxed_section_after_overlay">
                  <img src="img/insect-icon-5.png" alt="" />
                  <h4>Termite Control</h4>
                  <p>
                    Rhasellus at odio mattis, suscipit purus cursus,
                    pellentesque nunc. Proin ac dictum orcdrerit augue.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-4">
              <div className="boxed_section_single_service">
                <img className="img-responsive" src="img/insect-bg-6.jpg" alt="" />
                <h4>Pest Control</h4>
                <div className="boxed_section_before_overlay_img">
                  <div className="boxed_section_half_circle"></div>
                  <img src="img/insect-icon-6.png" alt="" />
                </div>
                <div className="boxed_section_after_overlay">
                  <img src="img/insect-icon-6.png" alt="" />
                  <h4>Termite Control</h4>
                  <p>
                    Rhasellus at odio mattis, suscipit purus cursus,
                    pellentesque nunc. Proin ac dictum orcdrerit augue.
                  </p>
                </div>
              </div>
            </div> */}
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="boxed_section_expertise_footer">
                <a href="" className="btn boxed_section_espertise_btn">
                  view all services
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BoxedSectionThree;
