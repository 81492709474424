import React from "react";
import { useSelector } from "react-redux";
import { extractFields } from "../../../utility";

export const BoxedSectionFifteenFields = [
  {
    type: "image",
    label: "Background Image",
    value: "",
    file: null,
    displayValue: "img/wasatch-bg.jpg",
  },
  {
    type: "string",
    label: "Main Title",
    value: "Why Lorem ipsum?",
  },
  {
    type: "string",
    label: "Main Description",
    value:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam at placerat diam. Aliquam semper diam vel mauris elementum fringilla. Sed a nisi metus. In hac habitasse platea dictumst. Nunc ac egestas magna, sed ultrices libero. Mauris sagittis nisl enim, vel euismod odio congue a. Vivamus sit amet porta ligula.",
  },
  {
    type: "string",
    label: "Title 1",
    value: "24/7 Availability",
  },
  {
    type: "string",
    label: "Description 1",
    value:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque tellus eros, aliquam quis cursus in, tempor sed eros. Etiam hendrerit justo non velit pretium pulvinar vitae nec turpis. Nulla a mi dolor. Cras a nibh ligula. Suspendisse quis tellus mattis, lacinia eros a, porttitor metus. Curabitur placerat velit et purus volutpat cursus. Proin consequat dolor id facilisis blandit. In id viverra erat. Sed maximus dapibus metus, ac rutrum magna consequat nec. Donec maximus, metus condimentum.",
  },
  {
    type: "image",
    label: "Icon 1",
    value: "",
    file: null,
    displayValue: "img/wasatch.png",
  },
  {
    type: "string",
    label: "Title 2",
    value: "24/7 Availability",
  },
  {
    type: "string",
    label: "Description 2",
    value:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque tellus eros, aliquam quis cursus in, tempor sed eros. Etiam hendrerit justo non velit pretium pulvinar vitae nec turpis. Nulla a mi dolor. Cras a nibh ligula. Suspendisse quis tellus mattis, lacinia eros a, porttitor metus. Curabitur placerat velit et purus volutpat cursus. Proin consequat dolor id facilisis blandit. In id viverra erat. Sed maximus dapibus metus, ac rutrum magna consequat nec. Donec maximus, metus condimentum.",
  },
  {
    type: "image",
    label: "Icon 2",
    value: "",
    file: null,
    displayValue: "img/wasatch-1.png",
  },
  {
    type: "string",
    label: "Title 3",
    value: "24/7 Availability",
  },
  {
    type: "string",
    label: "Description 3",
    value:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque tellus eros, aliquam quis cursus in, tempor sed eros. Etiam hendrerit justo non velit pretium pulvinar vitae nec turpis. Nulla a mi dolor. Cras a nibh ligula. Suspendisse quis tellus mattis, lacinia eros a, porttitor metus. Curabitur placerat velit et purus volutpat cursus. Proin consequat dolor id facilisis blandit. In id viverra erat. Sed maximus dapibus metus, ac rutrum magna consequat nec. Donec maximus, metus condimentum.",
  },
  {
    type: "image",
    label: "Icon 3",
    value: "",
    file: null,
    displayValue: "img/wasatch-2.png",
  },
  {
    type: "string",
    label: "Title 4",
    value: "24/7 Availability",
  },
  {
    type: "string",
    label: "Description 4",
    value:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque tellus eros, aliquam quis cursus in, tempor sed eros. Etiam hendrerit justo non velit pretium pulvinar vitae nec turpis. Nulla a mi dolor. Cras a nibh ligula. Suspendisse quis tellus mattis, lacinia eros a, porttitor metus. Curabitur placerat velit et purus volutpat cursus. Proin consequat dolor id facilisis blandit. In id viverra erat. Sed maximus dapibus metus, ac rutrum magna consequat nec. Donec maximus, metus condimentum.",
  },
  {
    type: "image",
    label: "Icon 4",
    value: "",
    file: null,
    displayValue: "img/wasatch-3.png",
  },
];
const BoxedSectionFifteen = (props) => {
  const boxedSectionsFields = useSelector((state) =>
    extractFields(state, "boxSection", props.instance, 14)
  );

  const bgImage =
    "url(" +
    boxedSectionsFields[0].displayValue.replace(/^"(.+(?="$))"$/, "$1") +
    ")";

  return (
    // <!-- Section Two Start -->

    <div
      style={{ backgroundImage: bgImage }}
      className="boxed_thirteen_wasatch_area"
    >
      <div className="container">
        <div className="boxed_thirteen_wasatch">
          <div className="row">
            <div className="col-sm-12">
              <div className="boxed_thirteen_wasatch_title boxed_thirteen_sec_title">
                <h2>{boxedSectionsFields[1].value}</h2>
                <p>{boxedSectionsFields[2].value}</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-3">
              <div className="boxed_thirteen_single_wasatch">
                <img src={boxedSectionsFields[5].displayValue} alt="" />
                <h3>{boxedSectionsFields[3].value}</h3>
                <p>{boxedSectionsFields[4].value}</p>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="boxed_thirteen_single_wasatch">
                <img src={boxedSectionsFields[8].displayValue} alt="" />
                <h3>{boxedSectionsFields[6].value}</h3>
                <p>{boxedSectionsFields[7].value}</p>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="boxed_thirteen_single_wasatch">
                <img src={boxedSectionsFields[11].displayValue} alt="" />
                <h3>{boxedSectionsFields[9].value}</h3>
                <p>{boxedSectionsFields[10].value}</p>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="boxed_thirteen_single_wasatch">
                <img src={boxedSectionsFields[14].displayValue} alt="" />
                <h3>{boxedSectionsFields[12].value}</h3>
                <p>{boxedSectionsFields[13].value}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BoxedSectionFifteen;
