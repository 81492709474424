import React from "react";
import { useSelector } from "react-redux";
import { extractFields } from "../../../utility";

export const SectionFourteenFields = [
  {
    type: "string",
    label: "Title",
    value: "We Offer Different Services",
  },
  {
    type: "string",
    label: "Description",
    value:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ut leo tempor, dictum nisi vitae, blandit risus. Morbi euismod justo ligula, vel dictum mauris maximus eget. Etiam nec nisi nec lectus commodo egestas.",
  },
];

const SectionFourteen = (props) => {
  const sectionFields = useSelector((state) =>
    extractFields(state, "section", props.instance, 13)
  );

  return (
    // <!-- Section Eight Start -->
    <div className="section_fourteen_area">
      <div className="container">
        <div className="section_fourteen">
          <div className="row">
            <div className="col-sm-6 col-md-5">
              <div className="section_fourteen_sec_text">
                <h2>{sectionFields[0].value}</h2>
              </div>
            </div>
            <div className="col-sm-6 col-md-7">
              <div className="section_fourteen_sec_text">
                <p>{sectionFields[1].value}</p>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionFourteen;
