import {
  LOGIN_START,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  CHECKING_AUTH_STATE,
} from "../../actionTypes";

import { auth, database } from "firebase";
import { resetLayout } from "../layout";

export const logout = () => {
  return (dispatch) => {
    auth()
      .signOut()
      .then((_) => {
        dispatch(resetLayout());
      })
      .then((_) => {
        dispatch({
          type: LOGOUT,
        });
      })
      .then((_) => {
        window.location.reload();
      });
  };
};

export const login = (email, password) => {
  return async (dispatch) => {
    dispatch({ type: LOGIN_START });
    try {
      const user = await auth().signInWithEmailAndPassword(email, password);
      auth().setPersistence(auth.Auth.Persistence.LOCAL);
      dispatch({ type: LOGIN_SUCCESS, user: user.user });
    } catch (e) {
      dispatch({ type: LOGIN_FAIL, error: "Wrong credentials." });
    }
  };
};
export const register = (email, password) => {
  return async (dispatch) => {
    dispatch({ type: LOGIN_START });
    try {
      const user = await auth().createUserWithEmailAndPassword(email, password);

      dispatch({ type: LOGIN_SUCCESS, user: user.user });
    } catch (e) {
      if (e) dispatch({ type: LOGIN_FAIL, error: e.message });
    }
  };
};
export const checkAuthState = () => {
  return (dispatch) => {
    dispatch({ type: LOGIN_START });
    auth().onAuthStateChanged((user) => {
      if (user) {
        dispatch({
          type: LOGIN_SUCCESS,
          user: user,
        });
      } else {
        dispatch({ type: LOGIN_FAIL });
      }
    });
    dispatch({ type: CHECKING_AUTH_STATE, checked: false });
  };
};
