import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { extractFields } from "../../../utility";

export const BoxedSectionTenFields = [
  {
    type: "string",
    label: "Title",
    value: "testimonial",
  },
  {
    type: "image",
    label: "Image 1",
    value: "",
    file: null,
    displayValue: "img/box-testi.jpg",
  },
  {
    type: "string",
    label: "Name 1",
    value: "THE JOHNSON FAMILY",
  },
  {
    type: "string",
    label: "Feedback 1",
    textare: true,
    value:
      "Visiting H. Paul Walls, DMD gives my family and me more reasons to smile.",
  },
  {
    type: "image",
    label: "Image 2",
    value: "",
    file: null,
    displayValue: "img/box-testi.jpg",
  },
  {
    type: "string",
    label: "Name 2",
    value: "THE JOHNSON FAMILY",
  },
  {
    type: "string",
    label: "Feedback 2",
    textare: true,
    value:
      "Visiting H. Paul Walls, DMD gives my family and me more reasons to smile.",
  },
];

const BoxedSectionTen = (props) => {
  const boxedSectionsFields = useSelector((state) =>
    extractFields(state, "boxSection", props.instance, 9)
  );

  useEffect(() => {
    window.boxedEight();
  }, []);

  return (
    // <!-- Section Two Start -->

    <div className="boxed_eight_testimonial_area">
      <div className="container">
        <div className="boxed_eight_testimonial">
          <div className="row">
            <div className="col-sm-12">
              <div className="boxed_eight_testimonial_Name">
                <h2 className="boxed_eight_sec_Name">
                  {boxedSectionsFields[0].value}
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="boxed_eight_testimonial_slider">
                <div className="boxed_eight_single_testimonial">
                  <div className="boxed_eight_testi_img">
                    <img src={boxedSectionsFields[1].displayValue} alt="" />
                  </div>
                  <div className="boxed_eight_testi_text">
                    <p>{boxedSectionsFields[3].value}</p>
                    <span>- {boxedSectionsFields[2].value}</span>
                  </div>
                </div>
              </div>
            </div>
            {/* 
              <div className="col-sm-12">
              <div className="boxed_eight_testimonial_slider">
                <div className="boxed_eight_single_testimonial">
                  <div className="boxed_eight_testi_img">
                    <img src={boxedSectionsFields[4].displayValue} alt="" />
                  </div>
                  <div className="boxed_eight_testi_text">
                    <p>{boxedSectionsFields[6].value}</p>
                    <span>- {boxedSectionsFields[5].value}</span>
                  </div>
                </div>
              </div>
              </div>
            */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BoxedSectionTen;
