import { updateObject, extractFields } from "../../../utility";
import {
  CHANGE_LAYOUT,
  CHANGE_ORDER,
  ADD_SECTION,
  DELETE_SECTION,
  CHANGE_FIELDS,
  SET_SECTIONS,
  SET_FIELDS,
  RESET_LAYOUT,
  SET_PREVIEW_URL,
  SET_MAIN_COLOR,
  SET_SECTION_COLOR,
} from "../../actionTypes";
import { UP, DOWN } from "../../../utility/constants";
import getData from "../../../data/data";
import initialState from "./initialState";
import { getInitialSettings } from "./initialState";

const changeLayout = (state, direction, index = 0) => {
  const updatedState = state;
  updatedState.updateDB += 1;
  const section = state.sections[index];
  if (direction === "back") {
    section.activeLayout = section.activeLayout - 1;
  } else {
    section.activeLayout = section.activeLayout + 1;
  }
  return { ...updatedState };
};

const changeOrder = (state, currentPosition, direction) => {
  const updatedState = state;
  updatedState.updateDB += 1;

  const section = state.sections[currentPosition];
  if (direction === UP) {
    const upperSection = updatedState.sections[currentPosition - 1];
    updatedState.sections[currentPosition - 1] = section;
    updatedState.sections[currentPosition] = upperSection;
  } else if (direction === DOWN) {
    const lowerSection = updatedState.sections[currentPosition + 1];
    updatedState.sections[currentPosition + 1] = section;
    updatedState.sections[currentPosition] = lowerSection;
  }
  return { ...updatedState };
};

const addSection = (state, sectionType, activeLayout, currentPosition) => {
  const updatedState = state;
  updatedState.updateDB += 1;

  let newInstance = 0;
  updatedState.sections.map((sec) => {
    if (sec.type === sectionType) {
      if (sec.instance >= newInstance) newInstance = sec.instance;
    }
  });

  const sections = updatedState.sections;
  sections.splice(currentPosition + 1, 0, {
    type: sectionType,
    activeLayout: activeLayout,
    instance: newInstance + 1,
    fields: getData()[sectionType],
    colorScheme: getInitialSettings(sectionType),
  });

  // const fields = updatedState.fields;
  // fields[sectionType].push(getData()[sectionType]);

  return { ...updatedState };
};

const deleteSection = (state, currentPosition, instance) => {
  const updatedState = state;
  updatedState.updateDB += 1;

  const sections = updatedState.sections;
  sections.splice(currentPosition, 1);
  return { ...updatedState };
};

const changeFields = (state, fields, sectionType, activeLayout, instance) => {
  const updatedState = state;
  updatedState.updateDB += 1;

  const updatedSections = updatedState.sections;
  const updatedSection = updatedSections.filter(
    (s) => s.instance === instance && s.type === sectionType
  )[0];
  updatedSection.fields[activeLayout] = [...fields];
  updatedSection.fields.map((otherLayoutFields) => {
    updatedSection.fields[activeLayout].map((field) => {
      if (otherLayoutFields) {
        otherLayoutFields.map((otherField) => {
          if (otherField.label === field.label) {
            otherField.value = field.value;
            if (otherField.type === "image") {
              otherField.displayValue = field.displayValue;
            }
          }
        });
      }
    });
  });

  return { ...updatedState };
};

const changeSectionColor = (
  state,
  sectionType,
  activeLayout,
  instance,
  colorScheme
) => {
  const updatedState = state;
  updatedState.updateDB += 1;

  const updatedSections = updatedState.sections;
  const updatedSection = updatedSections.filter(
    (s) => s.instance === instance && s.type === sectionType
  )[0];
  updatedSection.colorScheme = colorScheme;
  return { ...updatedState };
};

const resetLayout = (state) => {
  const updatedState = state;
  updatedState.updateDB = 0;
  updatedState.sections = [...initialState.sections];
  updatedState.fields = { ...initialState.fields };
  return { ...updatedState };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_LAYOUT:
      return changeLayout(state, action.direction, action.index);
    case CHANGE_ORDER:
      return changeOrder(state, action.currentPosition, action.direction);
    case ADD_SECTION:
      return addSection(
        state,
        action.sectionType,
        action.activeLayout,
        action.currentPosition
      );
    case DELETE_SECTION:
      return deleteSection(state, action.currentPosition, action.instance);
    case CHANGE_FIELDS:
      return changeFields(
        state,
        action.fields,
        action.sectionType,
        action.activeLayout,
        action.instance
      );
    case SET_SECTIONS:
      return updateObject(state, {
        sections: action.sections,
        updateDB: state.updateDB + 1,
        previewUrl: action.previewUrl,
        mainColor: action.mainColor || state.mainColor,
      });
    case SET_FIELDS:
      return updateObject(state, {
        fields: action.fields,
        updateDB: state.updateDB + 1,
      });
    case RESET_LAYOUT:
      return {
        ...state,
        sections: [...initialState.sections],
        fields: { ...initialState.fields },
        updateDB: 0,
      };
    case SET_PREVIEW_URL:
      return {
        ...state,
        previewUrl: action.previewUrl,
      };
    case SET_MAIN_COLOR:
      return {
        ...state,
        mainColor: action.mainColor,
        updateDB: state.updateDB + 1,
      };
    case SET_SECTION_COLOR:
      return changeSectionColor(
        state,
        action.sectionType,
        action.activeLayout,
        action.instance,
        action.colorScheme
      );
    default:
      return state;
  }
};

export default reducer;
