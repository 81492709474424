import React from "react";
import { useSelector } from "react-redux";
import { extractFields } from "../../../utility";

export const SectionFiveFields = [
  {
    type: "string",
    label: "Title",
    value: "The Most Trusted Team of Electricians In Austin",
  },
  {
    type: "string",
    label: "Sub Title",
    value:
      "Austin's local electrician. We are a full service electrical contractor specializing in residential projects. Give us a call today to discover the TruTec difference:",
  },
  {
    type: "string",
    label: "Phone",
    value: "512.645.0679",
  },
  {
    type: "string",
    label: "Offer Title",
    value: "$25 OFF",
  },
  {
    type: "string",
    label: "Offer Description",
    value: "Mention code NET when speaking  with our team",
  },
  {
    type: "image",
    label: "Background Image",
    value: "",
    file: null,
    displayValue: "img/s-team-bg.jpg",
  },
];

const SectionFive = (props) => {
  const sectionFields = useSelector((state) =>
    extractFields(state, "section", props.instance, 4)
  );

  const bgImage =
    "url(" +
    sectionFields[5].displayValue.replace(/^"(.+(?="$))"$/, "$1") +
    ")";

  return (
    <>
      <div style={{ background: bgImage }} className="section_one_team_area">
        <div className="container">
          <div className="section_one_team">
            <div className="row">
              <div className="col-sm-8 col-md-8 col-lg-6">
                <div className="section_one_single_team">
                  <h2>{sectionFields[0].value}</h2>
                  <p>{sectionFields[1].value}</p>
                  <a href="tel:512 645 0679" className="section_one_team_call">
                    {" "}
                    <i className="fa fa-phone"></i> {sectionFields[2].value}
                  </a>
                  <div className="section_one_offer_card text-center">
                    <div className="section_one_offer_card_inner">
                      <h3>{sectionFields[3].value}</h3>
                      <p>{sectionFields[4].value}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>{" "}
    </>
  );
};

export default SectionFive;
