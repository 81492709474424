import React from "react";
import { useSelector } from "react-redux";
import { extractFields } from "../../../utility";

export const BoxedSectionElevenFields = [
  {
    type: "image",
    label: "Image 1",
    value: "",
    file: null,
    displayValue: "img/box-testi.jpg",
  },
  {
    type: "string",
    label: "Title 1",
    value: "Custom Fabrication",
  },
  {
    type: "string",
    label: "Service 1",
    textare: true,
    value:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ut leo tempor, dictum nisi vitae, blandit risus. Morbi euismod justo ligula, vel dictum mauris maximus eget. Etiam nec nisi nec lectus commodo egestas. Etiam nec nisi nec lectus commodo egestas.",
  },
  {
    type: "button",
    label: ["Link text 1", "Link 1"],
    value: {
      text: "Service Detail",
      link: "#",
    },
  },
  {
    type: "image",
    label: "Image 2",
    value: "",
    file: null,
    displayValue: "img/box-testi.jpg",
  },
  {
    type: "string",
    label: "Title 2",
    value: "Custom Fabrication",
  },
  {
    type: "string",
    label: "Service 2",
    textare: true,
    value:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ut leo tempor, dictum nisi vitae, blandit risus. Morbi euismod justo ligula, vel dictum mauris maximus eget. Etiam nec nisi nec lectus commodo egestas. Etiam nec nisi nec lectus commodo egestas.",
  },
  {
    type: "button",
    label: ["Link text 2", "Link 2"],
    value: {
      text: "Service Detail",
      link: "#",
    },
  },
  {
    type: "image",
    label: "Image 3",
    value: "",
    file: null,
    displayValue: "img/box-testi.jpg",
  },
  {
    type: "string",
    label: "Title 3",
    value: "Custom Fabrication",
  },
  {
    type: "string",
    label: "Service 3",
    textare: true,
    value:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ut leo tempor, dictum nisi vitae, blandit risus. Morbi euismod justo ligula, vel dictum mauris maximus eget. Etiam nec nisi nec lectus commodo egestas. Etiam nec nisi nec lectus commodo egestas.",
  },
  {
    type: "button",
    label: ["Link text 3", "Link 3"],
    value: {
      text: "Service Detail",
      link: "#",
    },
  },
];

const BoxedSectionEleven = (props) => {
  const boxedSectionsFields = useSelector((state) =>
    extractFields(state, "boxSection", props.instance, 10)
  );

  return (
    // <!-- Section Two Start -->

    <div className="boxed_section_nine_area">
      <div className="container">
        <div className="boxed_section_nine">
          <div className="row">
            <div className="col-sm-4">
              <div className="boxed_nine_single_service">
                <img src={boxedSectionsFields[0].displayValue} alt="" />
                <h3>{boxedSectionsFields[1].value}n</h3>
                <p>{boxedSectionsFields[2].value}</p>
                <a
                  href={boxedSectionsFields[3].value.link}
                  className="button boxed_nine_servic_btn"
                >
                  {boxedSectionsFields[3].value.text}
                </a>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="boxed_nine_single_service">
                <img src={boxedSectionsFields[4].displayValue} alt="" />
                <h3>{boxedSectionsFields[5].value}n</h3>
                <p>{boxedSectionsFields[6].value}</p>
                <a
                  href={boxedSectionsFields[7].value.link}
                  className="button boxed_nine_servic_btn"
                >
                  {boxedSectionsFields[7].value.text}
                </a>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="boxed_nine_single_service">
                <img src={boxedSectionsFields[8].displayValue} alt="" />
                <h3>{boxedSectionsFields[9].value}n</h3>
                <p>{boxedSectionsFields[10].value}</p>
                <a
                  href={boxedSectionsFields[11].value.link}
                  className="button boxed_nine_servic_btn"
                >
                  {boxedSectionsFields[11].value.text}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BoxedSectionEleven;
