import React, { Suspense, useEffect, useState } from "react";
import * as firebase from 'firebase/app';
import 'firebase/auth';
// import * as firebase from "firebase";
import logo from "./logo.svg";
import {
  Route,
  Switch,
  Redirect,
  BrowserRouter,
  useRouteMatch,
  useLocation,
} from "react-router-dom";
import "./App.css";
import { useSelector, useDispatch } from "react-redux";
import { checkAuthState } from "./store/actions/auth";
import { FIREBASE_API_KEY, DB_URL } from "./utility/constants";
import { database } from "firebase";
import { SET_SECTIONS, SET_FIELDS } from "./store/actionTypes";
import jQuery from "jquery";
import * as $ from "jquery";

const Logout = React.lazy(() => import("./SiteComponents/Logout"));

const Home = React.lazy(() => import("./views/Home"));
const Register = React.lazy(() => import("./views/Register"));
const Composer = React.lazy(() => import("./views/Composer"));
const Preview = React.lazy(() =>
  import("./ComposerComponents/Preview/index.js")
);

const loading = () => (
  <div className="mx-auto my-auto text-center">
    <div className="pre-loader">
      <div className="loader anime">
        <div className="dots dot1"></div>
        <div className="dots dot2"></div>
        <div className="dots dot3"></div>
        <div className="dots dot4"></div>
      </div>
    </div>
  </div>
);

const App = () => {
  const dispatch = useDispatch();

  const [checking_auth_initial, setCheckingAuthInitial] = useState(false);
  const [fetchingData, setFetchingData] = useState(true);

  const checking_auth_state = useSelector(
    (state) => state.auth.checking_auth_state
  );
  const loggedIn = useSelector((state) => state.auth.success);
  const loginError = useSelector((state) => state.auth.error);
  const login_loading = useSelector((state) => state.auth.loading);

  const updateDB = useSelector((state) => state.layout.updateDB);
  const previewUrl = useSelector((state) => state.layout.previewUrl);
  const sections = useSelector((state) => state.layout.sections);
  const mainColor = useSelector((state) => state.layout.mainColor);
  // const fields = useSelector((state) => state.layout.fields);
  const user = useSelector((state) => state.auth.user);
  // const [previewUrl, setPreviewUrl] = useState(user?.uid);

  useEffect(() => {
    if (!checking_auth_initial) {
      setCheckingAuthInitial(false);
    }
  }, [checking_auth_state]);

  useEffect(() => {
    if (firebase.apps.length === 0) {
      const config = {
        apiKey: FIREBASE_API_KEY,
        databaseURL: DB_URL,
        storageBucket: "gs://coweb-eee86.appspot.com/",
      };
      firebase.initializeApp(config);
    }
    dispatch(checkAuthState());
  }, []);

  // const location = useLocation();

  useEffect(() => {
    if (user?.uid && window.location.pathname.includes("composer")) {
      database()
        .ref(user?.uid)
        .once("value", (snapshot) => {
          const childData = snapshot.val();
          if (childData) {
            const layoutData = childData;
            dispatch({
              type: SET_SECTIONS,
              sections: layoutData.sections,
              previewUrl: layoutData.previewUrl,
              mainColor: layoutData.mainColor
            });
            // dispatch({ type: SET_FIELDS, fields: layoutData.fields });
          } else {
            sections && dispatch({ type: SET_SECTIONS, sections });
            // fields && dispatch({ type: SET_FIELDS, fields });
          }
        });
    }
  }, [user]);

  useEffect(() => {
    if (user?.uid && window.location.pathname.includes("composer")) {
      if (updateDB >= 1 && sections) {
        database()
          .ref(user.uid)
          .set({ sections: sections, previewUrl: previewUrl || user.uid, mainColor: mainColor})
          .then((res) => {
            // if (updateDB === 1) {
            setFetchingData(false);
            // }
          })
          .catch((err) => {
            setFetchingData(false);
          });
      }
    }
  }, [updateDB]);

  useEffect(() => {
    var sc = $(window).scrollTop();
    if (sc > 100) {
      $(".header_area").addClass("sticky");
    } else {
      $(".header_area").removeClass("sticky");
    }
  }, []);

  return (
    <>
      {login_loading ? (
        loading()
      ) : (
        <BrowserRouter>
          {/* <ScrollToTop /> */}
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route
                exact
                path="/"
                render={() => {
                  if (loggedIn) {
                    return <Redirect to="/composer" />;
                  } else {
                    return <Redirect to="/login" />;
                  }
                }}
              />
              <Route
                exact
                path="/login"
                name="Home"
                render={(props) =>
                  loggedIn ? <Redirect to="/composer" /> : <Home {...props} />
                }
              />
              <Route
                exact
                path="/register"
                name="Home"
                render={(props) =>
                  loggedIn ? (
                    <Redirect to="/composer" />
                  ) : (
                    <Register {...props} />
                  )
                }
              />
              <Route exact path="/logout" name="Logout" component={Logout} />
              <Route
                path="/composer"
                name="Composer"
                render={(props) =>
                  !checking_auth_initial && !loggedIn ? (
                    <Redirect to="/login" />
                  ) : (
                    <>{fetchingData ? loading() : <Composer {...props} />}</>
                  )
                }
              />
              <Route
                path={"/:previewUrl"}
                name="Preview"
                render={
                  (props) => (
                    // loggedIn ? (
                    <Preview {...props} />
                  )
                  // ) : (
                  // <Home {...props} />
                  // )
                }
              />
            </Switch>
          </React.Suspense>
        </BrowserRouter>
      )}
    </>
  );
};

export default App;
