import {
  CHANGE_LAYOUT,
  CHANGE_ORDER,
  ADD_SECTION,
  DELETE_SECTION,
  CHANGE_FIELDS,
  RESET_LAYOUT,
  SET_MAIN_COLOR,
  SET_SECTION_COLOR,
} from "../../actionTypes";

export const changeLayout = (sectionType, direction, index) => {
  return {
    type: CHANGE_LAYOUT,
    sectionType,
    direction,
    index,
  };
};

export const changeOrder = (currentPosition, direction) => {
  return {
    type: CHANGE_ORDER,
    currentPosition,
    direction,
  };
};

export const addSection = (sectionType, activeLayout, currentPosition) => {
  return {
    type: ADD_SECTION,
    sectionType,
    activeLayout,
    currentPosition,
  };
};

export const deleteSection = (currentPosition, instance) => {
  return {
    type: DELETE_SECTION,
    currentPosition,
    instance,
  };
};

export const resetLayout = () => {
  return {
    type: RESET_LAYOUT,
  };
};

export const changeFields = (fields, sectionType, activeLayout, instance) => {
  return {
    type: CHANGE_FIELDS,
    fields,
    sectionType,
    activeLayout,
    instance,
  };
};

export const setMainColor = ({mainColor}) => {
  return {
    type: SET_MAIN_COLOR,
    mainColor,
  };
};

export const setSectionColor = (sectionType, activeLayout, instance, colorScheme) => {
  return {
    type: SET_SECTION_COLOR,
    sectionType,
    activeLayout,
    colorScheme,
    instance
  };
};