import React from "react";
import { useSelector } from "react-redux";
import { extractFields } from "../../../utility";

export const SectionSevenFields = [
  {
    type: "string",
    label: "Title",
    value: "About Us",
  },
  {
    type: "string",
    label: "Phone",
    value: "Get into Touch",
  },
  {
    type: "image",
    label: "Background Image",
    value: "",
    file: null,
    displayValue: "img/footer-banner.jpg",
  },
];

const SectionSeven = (props) => {
  const sectionFields = useSelector((state) =>
    extractFields(state, "section", props.instance, 6)
  );

  const bgImage =
    "url(" +
    sectionFields[2].displayValue.replace(/^"(.+(?="$))"$/, "$1") +
    ")";

  return (
    // <!-- Section Eight Start -->
    <div
      style={{ backgroundImage: bgImage }}
      className="section_one_footer_banner_area"
    >
      <div className="container">
        <div className="section_one_footer_banner">
          <h2>{sectionFields[0].value} </h2>
          <a href="tel:512-645-0679" className="btn section_one_footer_banner_btn">
            {" "}
            <i className="fa fa-phone"></i> Call Us Today:{" "}
            <span>{sectionFields[1].value}</span>
          </a>
        </div>
      </div>
    </div>
    // <!-- Section Eight End -->
  );
};

export default SectionSeven;
