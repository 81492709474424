import React from "react";
import { useSelector } from "react-redux";
import { extractFields } from "../../../utility";

export const BoxedSectionFouteenFields = [
  {
    type: "image",
    label: "Background Image 1",
    value: "",
    file: null,
    displayValue: "img/content-1.jpg",
  },
  {
    type: "string",
    label: "Title 1",
    value: "SEEKING COMPENSATION FOR A WORKPLACE ACCIDENT",
  },
  {
    type: "string",
    label: "Description  1",
    textare: true,
    value:
      "If you have been hurt in a job-related accident, you may be entitled to receive compensation for your medical expenses, lost wages, and other benefits through your employer’s insurer, regardless of fault, with the assistance of a New Orleans workers' compensation attorney.",
  },
  {
    type: "button",
    label: ["Link text 1", "Link 1"],
    value: {
      text: "Learn more",
      link: "#",
    },
  },
  {
    type: "image",
    label: "Background Image 2",
    value: "",
    file: null,
    displayValue: "img/content-2.jpg",
  },
  {
    type: "string",
    label: "Title 2",
    value: "SEEKING COMPENSATION FOR A WORKPLACE ACCIDENT",
  },
  {
    type: "string",
    label: "Description  2",
    textare: true,
    value:
      "If you have been hurt in a job-related accident, you may be entitled to receive compensation for your medical expenses, lost wages, and other benefits through your employer’s insurer, regardless of fault, with the assistance of a New Orleans workers' compensation attorney.",
  },
  {
    type: "button",
    label: ["Link text 2", "Link 2"],
    value: {
      text: "Learn more",
      link: "#",
    },
  },
  {
    type: "image",
    label: "Background Image 3",
    value: "",
    file: null,
    displayValue: "img/content-3.jpg",
  },
  {
    type: "string",
    label: "Title 3",
    value: "SEEKING COMPENSATION FOR A WORKPLACE ACCIDENT",
  },
  {
    type: "string",
    label: "Description  3",
    textare: true,
    value:
      "If you have been hurt in a job-related accident, you may be entitled to receive compensation for your medical expenses, lost wages, and other benefits through your employer’s insurer, regardless of fault, with the assistance of a New Orleans workers' compensation attorney.",
  },
  {
    type: "button",
    label: ["Link text 3", "Link 3"],
    value: {
      text: "Learn more",
      link: "#",
    },
  },
];

const BoxedSectionFourteen = (props) => {
  const boxedSectionsFields = useSelector((state) =>
    extractFields(state, "boxSection", props.instance, 13)
  );

  const bgImage1 =
    "url(" +
    boxedSectionsFields[0].displayValue.replace(/^"(.+(?="$))"$/, "$1") +
    ")";
  const bgImage2 =
    "url(" +
    boxedSectionsFields[4].displayValue.replace(/^"(.+(?="$))"$/, "$1") +
    ")";
  const bgImage3 =
    "url(" +
    boxedSectionsFields[8].displayValue.replace(/^"(.+(?="$))"$/, "$1") +
    ")";

  return (
    // <!-- Section Two Start -->

    <div className="boxed_twelve_main_content_area">
      <div className="container">
        <div className="boxed_twelve_main_content">
          <div className="row">
            <div className="col-sm-12">
              <div className="boxed_twelve_single_content_wrap">
                <div
                  style={{ backgroundImage: bgImage1 }}
                  className="boxed_twelve_single_content bg-1"
                >
                  <h3>{boxedSectionsFields[1].value}</h3>
                  <p>{boxedSectionsFields[2].value}</p>
                  <a
                    href={boxedSectionsFields[3].value.link}
                    className="boxed_twelve_lrn_more_btn"
                  >
                    {boxedSectionsFields[3].value.text}{" "}
                    <i className="fa fa-angle-right"></i>
                  </a>
                </div>
                <div
                  style={{ backgroundImage: bgImage2 }}
                  className="boxed_twelve_single_content bg-2"
                >
                  <h3>{boxedSectionsFields[5].value}</h3>
                  <p>{boxedSectionsFields[6].value}</p>
                  <a
                    href={boxedSectionsFields[7].value.link}
                    className="boxed_twelve_lrn_more_btn"
                  >
                    {boxedSectionsFields[7].value.text}{" "}
                    <i className="fa fa-angle-right"></i>
                  </a>
                </div>
                <div
                  style={{ backgroundImage: bgImage3 }}
                  className="boxed_twelve_single_content bg-3"
                >
                  <h3>{boxedSectionsFields[9].value}</h3>
                  <p>{boxedSectionsFields[10].value}</p>
                  <a
                    href={boxedSectionsFields[11].value.link}
                    className="boxed_twelve_lrn_more_btn"
                  >
                    {boxedSectionsFields[11].value.text}{" "}
                    <i className="fa fa-angle-right"></i>
                  </a>
                </div>
                <div className="boxed_twelve_single_content bg-4">
                  <h3>Seeking Compensation for a Workplace Accident</h3>
                  <p>
                    If you have been hurt in a job-related accident, you may be
                    entitled to receive compensation for your medical expenses,
                    lost wages, and other benefits through your employer’s
                    insurer, regardless of fault, with the assistance of a New
                    Orleans workers' compensation attorney.
                  </p>
                  <a href="#" className="boxed_twelve_lrn_more_btn">
                    learn more <i className="fa fa-angle-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BoxedSectionFourteen;
