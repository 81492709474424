import React from "react";
import { useSelector } from "react-redux";
import { extractFields } from "../../../utility";

export const bannerThreeFields = [
  {
    type: "string",
    label: "Title",
    value: "ford concrete",
  },
  {
    type: "string",
    label: "Sub Title",
    value: "When you’ve tried the rest.",
  },
  {
    type: "image",
    label: "Background Image",
    value: "",
    file: null,
    displayValue: "img/banner-three.jpg",
  },
];

const BannerThree = (props) => {
  const bannerFields = useSelector((state) =>
    extractFields(state, "banner", props.instance, 2)
  );

  return (
    // <!-- Banner One Start -->
    <div
      style={{
        backgroundImage: `url('${bannerFields[2].displayValue.replace(
          /^"(.+(?="$))"$/,
          "$1"
        )}')`,
      }}
      className="banner_area_three"
    >
      <div className="container">
        <div className="banner_three">
          <div className="row">
            <div className="col-sm-12">
              <div className="bnr_text_three">
                <h1>{bannerFields[0].value}</h1>
                <h3>{bannerFields[1].value}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerThree;
