import { headerThreeFields } from "../ComposerComponents/headers/HeaderThree";
import { bannerThreeFields } from "../ComposerComponents/banners/BannerThree";
import { TitleThreeFields } from "../ComposerComponents/titles/TitleThree";
import { TitleFourFields } from "../ComposerComponents/titles/TitleFour";
import { FooterThreeFields } from "../ComposerComponents/footers/FooterThree";
import { SectionThreeFields } from "../ComposerComponents/sections/SectionThree";
import { SectionFourFields } from "../ComposerComponents/sections/SectionFour";
import { SectionFiveFields } from "../ComposerComponents/sections/SectionFive";
import { SectionSixFields } from "../ComposerComponents/sections/SectionSix";
import { SectionSevenFields } from "../ComposerComponents/sections/SectionSeven";
import { SectionEightFields } from "../ComposerComponents/sections/SectionEight";
import { SectionNineFields } from "../ComposerComponents/sections/SectionNine";
import { SectionTenFields } from "../ComposerComponents/sections/SectionTen";
import { SectionElevenFields } from "../ComposerComponents/sections/SectionEleven";
import SectionEleven from "../ComposerComponents/sections/SectionEleven";
import { SectionTwelveFields } from "../ComposerComponents/sections/SectionTwelve";
import { SectionThirteenFields } from "../ComposerComponents/sections/SectionThirteen";
import { SectionFifteenFields } from "../ComposerComponents/sections/SectionFifteen";
import { SectionSixteenFields } from "../ComposerComponents/sections/SectionSixteen";
import { SectionSeventeenFields } from "../ComposerComponents/sections/SectionSeventeen";
import { SectionFourteenFields } from "../ComposerComponents/sections/SectionFourteen";
import { BoxedSectionThreeFields } from "../ComposerComponents/boxedSections/BoxedSectionThree";
import { BoxedSectionFourFields } from "../ComposerComponents/boxedSections/BoxedSectionFour";
import { BoxedSectionFiveFields } from "../ComposerComponents/boxedSections/BoxedSectionFive";
import { BoxedSectionSevenFields } from "../ComposerComponents/boxedSections/BoxedSectionSeven";
import { BoxedSectionEightFields } from "../ComposerComponents/boxedSections/BoxedSectionEight";
import BoxedSectionSeven from "../ComposerComponents/boxedSections/BoxedSectionSeven";
import BoxedSectionEight from "../ComposerComponents/boxedSections/BoxedSectionEight";
import { BoxedSectionNineFields } from "../ComposerComponents/boxedSections/BoxedSectionNine";
import { BoxedSectionTenFields } from "../ComposerComponents/boxedSections/BoxedSectionTen";
import { BoxedSectionElevenFields } from "../ComposerComponents/boxedSections/BoxedSectionEleven";
import { BoxedSectionTwelveFields } from "../ComposerComponents/boxedSections/BoxedSectionTwelve";
import { BoxedSectionThirteenFields } from "../ComposerComponents/boxedSections/BoxedSectionThirteen";
import { BoxedSectionFouteenFields } from "../ComposerComponents/boxedSections/BoxedSectionFouteen";
import { BoxedSectionFifteenFields } from "../ComposerComponents/boxedSections/BoxedSectionFifteen";
import { BoxedSectionSixteenFields } from "../ComposerComponents/boxedSections/BoxedSectionSixteen";
import { BoxedSectionSeventeenFields } from "../ComposerComponents/boxedSections/BoxedSectionSeventeen";
import { BoxedSectionSixFields } from "../ComposerComponents/boxedSections/BoxedSectionSix";

const getData = () => ({
  header: [
    [
      {
        type: "button",
        label: ["Link text 1", "Link 1"],
        value: {
          text: "home",
          link: "#",
        },
      },
      {
        type: "button",
        label: ["Link text 2", "Link 2"],
        value: {
          text: "blog",
          link: "#",
        },
      },
      {
        type: "button",
        label: ["Link text 3", "Link 3"],
        value: {
          text: "services",
          link: "#",
        },
      },
      {
        type: "button",
        label: ["Link text 4", "Link 4"],
        value: {
          text: "about",
          link: "#",
        },
      },

      {
        type: "button",
        label: ["Link text 5", "Link 5"],
        value: {
          text: "contact",
          link: "#",
        },
      },

      {
        type: "string",
        label: "Title",
        value: "We Get Dentists More of What They Want",
      },

      {
        type: "image",
        label: "Background Image",
        value: "",
        file: null,
        displayValue: "img/one-header-bg.jpg",
      },
      {
        type: "image",
        label: "Logo",
        value: "",
        file: null,
        displayValue: "img/one-logo.png",
      },
      {
        type: "button",
        label: ["Button 1", "Button 1 Link"],
        value: {
          text: "get a quote",
          link: "#",
        },
      },
      {
        type: "button",
        label: ["Button 2", "Button 2 Link"],
        value: {
          text: "get services now",
          link: "#",
        },
      },
    ],
    [
      {
        type: "string",
        label: "Title",
        value: "We Get Dentists More of What They Want",
      },
      {
        type: "string",
        label: "Person Name 1",
        value: "Denver",
      },
      {
        type: "string",
        label: "Person 1 Phone",
        value: "720-120-1512",
      },
      {
        type: "string",
        label: "Person 2 Name",
        value: "Denver 2",
      },
      {
        type: "string",
        label: "Person 2 Phone",
        value: "720-120-1512",
      },

      {
        type: "image",
        label: "Logo",
        value: "",
        file: null,
        displayValue: "img/logo-two.png",
      },
      {
        type: "button",
        label: ["Link text 1", "Link 1"],
        value: {
          text: "home",
          link: "#",
        },
      },
      {
        type: "button",
        label: ["Link text 2", "Link 2"],
        value: {
          text: "blog",
          link: "#",
        },
      },
      {
        type: "button",
        label: ["Link text 3", "Link 3"],
        value: {
          text: "services",
          link: "#",
        },
      },
      {
        type: "button",
        label: ["Link text 4", "Link 4"],
        value: {
          text: "about",
          link: "#",
        },
      },

      {
        type: "button",
        label: ["Link text 5", "Link 5"],
        value: {
          text: "contact",
          link: "#",
        },
      },
      {
        type: "button",
        label: ["Link text 6", "Link 6"],
        value: {
          text: "privacy",
          link: "#",
        },
      },
    ],
    headerThreeFields,
  ],
  banner: [
    [
      {
        type: "string",
        label: "Title",
        value: "Get insurance for almost anything you need",
      },
      {
        type: "string",
        label: "Sub Title",
        value: "Get insurance for almost anything you need",
      },
      {
        type: "image",
        label: "Background Image",
        value: "",
        file: null,
        displayValue: "img/banner-one.jpg",
      },
    ],
    [
      {
        type: "string",
        label: "Title",
        value: "Get insurance for almost anything you need",
      },
      {
        type: "string",
        label: "Sub Title",
        value: "Get insurance for almost anything you need",
      },
      {
        type: "button",
        label: ["Button text", "Link"],
        value: {
          text: "book an appointment",
          link: "#",
        },
      },
      {
        type: "image",
        label: "Background Image",
        value: "",
        file: null,
        displayValue: "img/banner-two.jpg",
      },
    ],
    bannerThreeFields,
  ],
  title: [
    [
      {
        type: "string",
        label: "Title",
        value: "Get insurance for almost anything you need",
      },
    ],
    [
      { type: "string", label: "Title", value: "OUR SERVICES" },
      { type: "string", label: "Sub Title", value: "46 YEARS OF EXPERIENCE" },
    ],
    TitleThreeFields,
    TitleFourFields,
  ],
  boxSection: [
    [
      {
        type: "string",
        label: "Title 1",
        value: "GREAT REVIEWS",
      },
      {
        type: "string",
        label: "Title 2",
        value: "GREAT REVIEWS",
      },
      {
        type: "string",
        label: "Title 3",
        value: "GREAT REVIEWS",
      },
      {
        type: "string",
        label: "Title 4",
        value: "GREAT REVIEWS",
      },
      {
        type: "string",
        label: "Title 5",
        value: "GREAT REVIEWS",
      },
      {
        type: "string",
        label: "Count 1",
        value: "150+",
      },
      {
        type: "string",
        label: "Count 2",
        value: "150+",
      },
      {
        type: "string",
        label: "Count 3",
        value: "150+",
      },
      {
        type: "string",
        label: "Count 4",
        value: "150+",
      },
      {
        type: "string",
        label: "Count 5",
        value: "150+",
      },
      {
        type: "image",
        label: "Icon 1",
        value: "",
        displayValue: "img/expertise-1.png",
        file: null,
      },
      {
        type: "image",
        label: "Icon 2",
        value: "",
        displayValue: "img/expertise-2.png",
        file: null,
      },
      {
        type: "image",
        label: "Icon 3",
        value: "",
        displayValue: "img/expertise-3.png",
        file: null,
      },
      {
        type: "image",
        label: "Icon 4",
        value: "",
        displayValue: "img/expertise-4.png",
        file: null,
      },
      {
        type: "image",
        label: "Icon 5",
        value: "",
        displayValue: "img/expertise-5.png",
        file: null,
      },
    ],
    [
      {
        type: "string",
        label: "Title 1",
        value: "GREAT REVIEWS",
      },
      {
        type: "string",
        label: "Title 2",
        value: "GREAT REVIEWS",
      },
      {
        type: "string",
        label: "Title 3",
        value: "GREAT REVIEWS",
      },
      {
        type: "string",
        label: "Title 4",
        value: "GREAT REVIEWS",
      },
      {
        type: "image",
        label: "Icon 1",
        value: "",
        displayValue: "img/sn-1.png",
        file: null,
      },
      {
        type: "image",
        label: "Icon 2",
        value: "",
        displayValue: "img/sn-2.png",
        file: null,
      },
      {
        type: "image",
        label: "Icon 3",
        value: "",
        displayValue: "img/sn-3.png",
        file: null,
      },
      {
        type: "image",
        label: "Icon 4",
        value: "",
        displayValue: "img/sn-4.png",
        file: null,
      },
    ],
    BoxedSectionThreeFields,
    BoxedSectionFourFields,
    BoxedSectionFiveFields,
    BoxedSectionSixFields,
    BoxedSectionSevenFields,
    BoxedSectionEightFields,
    BoxedSectionNineFields,
    BoxedSectionTenFields,
    BoxedSectionElevenFields,
    BoxedSectionTwelveFields,
    BoxedSectionThirteenFields,
    BoxedSectionFouteenFields,
    BoxedSectionFifteenFields,
    BoxedSectionSixteenFields,
    BoxedSectionSeventeenFields,
  ],
  section: [
    [
      {
        type: "string",
        label: "Title",
        value: "WELCOME TO LABRADO CHIROPRACTIC",
      },
      {
        type: "string",
        label: "Sub Title",
        value:
          "If you are seeking a drug and surgery-free alternative to alleviate your back or neck pain then you’ve come to the right place.",
      },
      {
        type: "string",
        label: "Description",
        textare: true,
        value:
          "Our highly trained and certified chiropractors have offered safe, natural, and effective chiropracticcare to the people of Galt and the surrounding areas for many years. All of our chiropractors have years of experience drawing on traditional chiropractic techniques as well as modern corrective methods to heal the mind, body, and spirit. As many of our loyal Galt clients can already attest to, when you come to Labrado Chiropractic we provide much more than pain relief. Our approach to chiropractic aims to improve your posture, mobility, and overall wellbeing.",
      },
    ],
    [
      {
        type: "string",
        label: "Description",
        value:
          "Our highly trained and certified chiropractors have offered safe, natural, and effective chiropracticcare to the people of Galt and the surrounding areas for many years. All of our chiropractors have years of experience drawing on traditional chiropractic techniques as well as modern corrective methods to heal the mind, body, and spirit. As many of our loyal Galt clients can already attest to, when you come to Labrado Chiropractic we provide much more than pain",
      },
    ],
    SectionThreeFields,
    SectionFourFields,
    SectionFiveFields,
    SectionSixFields,
    SectionSevenFields,
    SectionEightFields,
    SectionNineFields,
    SectionTenFields,
    SectionElevenFields,
    SectionTwelveFields,
    SectionThirteenFields,
    SectionFourteenFields,
    SectionFifteenFields,
    SectionSixteenFields,
    SectionSeventeenFields,
  ],
  footer: [
    [
      {
        type: "string",
        label: "Title",
        value: "About Us",
      },
      {
        type: "string",
        label: "Title 2",
        value: "Get into Touch",
      },
      {
        type: "string",
        label: "Description",
        textare: true,
        value:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc in velit elit. Nullam pretium in quam id facilisis. Aliquam non lacus elit. Donec fringilla porttitor leo, sed vestibulum mauris maximus a. Mauris semper et eros vel volutpat. Etiam quis bibendum odio. Donec commodo tortor nisl, a tincidunt justo sollicitudin a.",
      },
    ],
    [
      {
        type: "string",
        label: "Title",
        value: "CONTACT US",
      },
      {
        type: "string",
        label: "Person 1 Name",
        value: "DENNIS FORD",
      },
      {
        type: "string",
        label: "Person 1 Phone1",
        value: "(801) 254-7072",
      },
      {
        type: "string",
        label: "Person 1 Phone2",
        value: "(801) 254-7072",
      },
      {
        type: "string",
        label: "Person 2 Name",
        value: "DENNIS FORD",
      },
      {
        type: "string",
        label: "Person 2 Phone1",
        value: "(801) 254-7072",
      },
      {
        type: "string",
        label: "Person 2 Phone2",
        value: "(801) 254-7072",
      },
      {
        type: "string",
        label: "Address",
        value: "111 Charleston, sc",
      },
    ],
    FooterThreeFields,
  ],
});

export default getData;
